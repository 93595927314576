// src/components/OpenAPISchemaManagement.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import yaml from 'js-yaml';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Editor from '@monaco-editor/react';
import {
  FaPlus,
  FaPen,
  FaTrash,
  FaFileAlt,
  FaExclamationTriangle,
  FaSpinner,
  FaCheckCircle,
  FaArrowLeft,
} from 'react-icons/fa';
import './OpenAPISchemaManagement.css'; // We'll create this CSS file next

const OpenAPISchemaManagement = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [schemaContent, setSchemaContent] = useState('');
  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [parameters, setParameters] = useState({});
  const [response, setResponse] = useState(null);
  const [testError, setTestError] = useState(null);
  const [apiKeys, setApiKeys] = useState({});
  const [securitySchemes, setSecuritySchemes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deletingId, setDeletingId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchSchemas(token);
    }
  }, [user]);

  useEffect(() => {
    if (schemaContent) {
      parseSecuritySchemes();
    } else {
      setSecuritySchemes([]);
      setApiKeys({});
    }
  }, [schemaContent]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setError(t('openAPISchemaManagement.fetchUserError'));
      setUser(null);
    }
  };

  const fetchSchemas = async (token) => {
    try {
      const response = await axios.get('/api/ai-assistants/openapi-schemas', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSchemas(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch schemas:', error);
      setError(t('openAPISchemaManagement.fetchSchemasError'));
      setLoading(false);
    }
  };

  const fetchOperations = async (schemaId, token) => {
    try {
      const response = await axios.get(
        `/api/ai-assistants/openapi-schemas/${schemaId}/operations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOperations(response.data);
    } catch (error) {
      console.error('Failed to fetch operations:', error);
      setError(t('openAPISchemaManagement.fetchOperationsError'));
    }
  };

  const parseSecuritySchemes = () => {
    try {
      const parsedSchema = yaml.load(schemaContent);
      const components = parsedSchema.components || {};
      const securitySchemesObj = components.securitySchemes || {};
      const schemes = Object.keys(securitySchemesObj);
      setSecuritySchemes(schemes);

      // Initialize apiKeys state with empty strings for each scheme
      const initialApiKeys = {};
      schemes.forEach((scheme) => {
        const secScheme = securitySchemesObj[scheme];
        const secType = secScheme.type;
        if (secType === 'http' && secScheme.scheme === 'basic') {
          initialApiKeys[`${scheme}_username`] = '';
          initialApiKeys[`${scheme}_password`] = '';
        } else {
          initialApiKeys[scheme] = '';
        }
      });
      setApiKeys(initialApiKeys);
    } catch (error) {
      console.error('Failed to parse schema content:', error);
      setSecuritySchemes([]);
      setApiKeys({});
      setError(t('openAPISchemaManagement.parseError'));
    }
  };

  const handleCreateSchema = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/ai-assistants/openapi-schemas',
        {
          title,
          description,
          schema_content: schemaContent,
          api_keys: apiKeys,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchSchemas(token);
      setSuccessMessage(t('openAPISchemaManagement.createSuccess'));
      resetForm();
    } catch (error) {
      console.error('Failed to create schema:', error);
      setError(t('openAPISchemaManagement.createError'));
    }
  };

  const handleUpdateSchema = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/ai-assistants/openapi-schemas/${selectedSchema._id}`,
        {
          title,
          description,
          schema_content: schemaContent,
          api_keys: apiKeys,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchSchemas(token);
      setSuccessMessage(t('openAPISchemaManagement.updateSuccess'));
      resetForm();
    } catch (error) {
      console.error('Failed to update schema:', error);
      setError(t('openAPISchemaManagement.updateError'));
    }
  };

  const handleDeleteSchema = async (schemaId) => {
    const confirmDelete = window.confirm(t('openAPISchemaManagement.confirmDelete'));
    if (!confirmDelete) return;

    try {
      setDeletingId(schemaId);
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/ai-assistants/openapi-schemas/${schemaId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchSchemas(token);
      setSuccessMessage(t('openAPISchemaManagement.deleteSuccess'));
    } catch (error) {
      console.error('Failed to delete schema:', error);
      setError(t('openAPISchemaManagement.deleteError'));
    } finally {
      setDeletingId(null);
    }
  };

  const handleSelectSchema = (schema) => {
    if (selectedSchema === schema) {
      resetForm();
    } else {
      setSelectedSchema(schema);
      setTitle(schema.title);
      setDescription(schema.description);
      setSchemaContent(schema.schema_content);
      setApiKeys(schema.api_keys || {});
      setResponse(null);
      setTestError(null);
      // Fetch operations for the selected schema
      const token = sessionStorage.getItem('access_token');
      fetchOperations(schema._id, token);
    }
  };

  const handleSelectOperation = (operation) => {
    setSelectedOperation(operation);
    // Initialize parameters
    const initialParams = {};
    operation.parameters.forEach((param) => {
      initialParams[param.name] = '';
    });
    setParameters(initialParams);
    setResponse(null);
    setTestError(null);
  };

  const handleParameterChange = (paramName, value) => {
    setParameters({ ...parameters, [paramName]: value });
  };

  const handleInvoke = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
      setResponse(null);
      setTestError(null);
      const response = await axios.post(
        '/api/ai-assistants/openapi-schemas/invoke',
        {
          operation_id: selectedOperation.operationId,
          parameters: parameters,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setResponse(response.data);
    } catch (error) {
      console.error('API invocation failed:', error);
      setTestError(error.response ? error.response.data.detail : 'Unknown error');
    }
  };

  const resetForm = () => {
    setSelectedSchema(null);
    setTitle('');
    setDescription('');
    setSchemaContent('');
    setSecuritySchemes([]);
    setApiKeys({});
    setOperations([]);
    setSelectedOperation(null);
    setParameters({});
    setResponse(null);
    setTestError(null);
    setError('');
    setSuccessMessage('');
  };

  if (loading) {
    return (
      <div className="dashboard-container">
        <div className="loading-spinner">
          <FaSpinner className="spinner" /> {t('openAPISchemaManagement.loading')}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-container">
        <div className="error-message">
          <FaExclamationTriangle /> {error}
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-card openapi-schema-management">
        <h2 className="section-title">
          <FaFileAlt className="section-icon" />
          {t('openAPISchemaManagement.title')}
        </h2>
        <div className="management-container">
          {/* Schemas List */}
          <div className="schemas-list">
            <div className="schemas-header">
              <h3>{t('openAPISchemaManagement.yourSchemas')}</h3>
              <button
                className="icon-button add-button"
                onClick={() => setSelectedSchema(null)}
                aria-label={t('openAPISchemaManagement.addSchema')}
              >
                <FaPlus />
              </button>
            </div>
            <ul>
              {schemas.map((schema) => (
                <li
                  key={schema._id}
                  className={`schema-item ${selectedSchema === schema ? 'selected' : ''}`}
                  onClick={() => handleSelectSchema(schema)}
                >
                  <span className="schema-title">{schema.title}</span>
                  <div className="schema-actions">
                    <button
                      className="icon-button delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSchema(schema._id);
                      }}
                      aria-label={t('openAPISchemaManagement.deleteAriaLabel', { title: schema.title })}
                      disabled={deletingId === schema._id}
                    >
                      {deletingId === schema._id ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        <FaTrash />
                      )}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Schema Form */}
          <div className="schema-form">
            <h3>{selectedSchema ? t('openAPISchemaManagement.editSchema') : t('openAPISchemaManagement.createSchema')}</h3>
            <div className="form-group">
              <label htmlFor="title">{t('openAPISchemaManagement.titleLabel')}:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                aria-required="true"
                placeholder={t('openAPISchemaManagement.titlePlaceholder')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">{t('openAPISchemaManagement.descriptionLabel')}:</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                aria-required="true"
                placeholder={t('openAPISchemaManagement.descriptionPlaceholder')}
              ></textarea>
            </div>
            <div className="form-group">
              <label>{t('openAPISchemaManagement.schemaLabel')}:</label>
              <div className="code-editor">
                <Editor
                  height="400px"
                  language="yaml"
                  value={schemaContent}
                  onChange={(value) => setSchemaContent(value)}
                  theme="light"
                />
              </div>
            </div>
            {securitySchemes.length > 0 && (
              <div className="form-group">
                <label>{t('openAPISchemaManagement.apiKeysLabel')}:</label>
                {securitySchemes.map((scheme) => {
                  const parsedSchema = yaml.load(schemaContent);
                  const secScheme = parsedSchema.components.securitySchemes[scheme];
                  const secType = secScheme.type;
                  return (
                    <div key={scheme} className="security-scheme">
                      <label>{scheme}:</label>
                      {secType === 'http' && secScheme.scheme === 'basic' ? (
                        <>
                          <input
                            type="text"
                            placeholder={t('openAPISchemaManagement.usernamePlaceholder')}
                            value={apiKeys[`${scheme}_username`] || ''}
                            onChange={(e) =>
                              setApiKeys({ ...apiKeys, [`${scheme}_username`]: e.target.value })
                            }
                            aria-label={t('openAPISchemaManagement.usernameAriaLabel', { scheme })}
                          />
                          <input
                            type="password"
                            placeholder={t('openAPISchemaManagement.passwordPlaceholder')}
                            value={apiKeys[`${scheme}_password`] || ''}
                            onChange={(e) =>
                              setApiKeys({ ...apiKeys, [`${scheme}_password`]: e.target.value })
                            }
                            aria-label={t('openAPISchemaManagement.passwordAriaLabel', { scheme })}
                          />
                        </>
                      ) : (
                        <input
                          type="text"
                          value={apiKeys[scheme] || ''}
                          onChange={(e) => setApiKeys({ ...apiKeys, [scheme]: e.target.value })}
                          aria-label={t('openAPISchemaManagement.apiKeyAriaLabel', { scheme })}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="button-group">
              {selectedSchema ? (
                <button
                  className="primary-button update-button"
                  onClick={handleUpdateSchema}
                  aria-label={t('openAPISchemaManagement.updateSchema')}
                >
                  <FaPen /> {t('openAPISchemaManagement.updateSchema')}
                </button>
              ) : (
                <button
                  className="primary-button create-button"
                  onClick={handleCreateSchema}
                  aria-label={t('openAPISchemaManagement.createSchema')}
                >
                  <FaPlus /> {t('openAPISchemaManagement.createSchema')}
                </button>
              )}
            </div>

            {/* Feedback Messages */}
            {error && (
              <div className="feedback-message error">
                <FaExclamationTriangle /> {error}
              </div>
            )}
            {successMessage && (
              <div className="feedback-message success">
                <FaCheckCircle /> {successMessage}
              </div>
            )}

            {/* Testing Interface */}
            {selectedSchema && operations.length > 0 && (
              <div className="testing-section">
                <h3>{t('openAPISchemaManagement.testingTitle')}</h3>
                <div className="form-group">
                  <label>{t('openAPISchemaManagement.selectOperation')}:</label>
                  <select
                    value={selectedOperation ? selectedOperation.operationId : ''}
                    onChange={(e) => {
                      const operation = operations.find(
                        (op) => op.operationId === e.target.value
                      );
                      handleSelectOperation(operation);
                    }}
                    aria-label={t('openAPISchemaManagement.selectOperationAriaLabel')}
                  >
                    <option value="">{t('openAPISchemaManagement.selectOperationPlaceholder')}</option>
                    {operations.map((op) => (
                      <option key={op.operationId} value={op.operationId}>
                        {op.operationId} ({op.method.toUpperCase()} {op.path})
                      </option>
                    ))}
                  </select>
                </div>
                {selectedOperation && (
                  <div className="parameters-section">
                    <h4>{t('openAPISchemaManagement.parametersTitle')}</h4>
                    {selectedOperation.parameters.length > 0 ? (
                      selectedOperation.parameters.map((param) => (
                        <div className="form-group" key={param.name}>
                          <label>
                            {param.name} {param.required ? '*' : ''} ({param.in}):
                          </label>
                          <input
                            type="text"
                            value={parameters[param.name] || ''}
                            onChange={(e) => handleParameterChange(param.name, e.target.value)}
                            placeholder={t('openAPISchemaManagement.parameterPlaceholder')}
                            aria-required={param.required}
                            aria-label={t('openAPISchemaManagement.parameterAriaLabel', { name: param.name })}
                          />
                        </div>
                      ))
                    ) : (
                      <p>{t('openAPISchemaManagement.noParameters')}</p>
                    )}
                    <button
                      className="primary-button invoke-button"
                      onClick={handleInvoke}
                      aria-label={t('openAPISchemaManagement.invokeOperation')}
                    >
                      {t('openAPISchemaManagement.invokeOperation')}
                    </button>
                  </div>
                )}
                {testError && (
                  <div className="feedback-message error">
                    <FaExclamationTriangle /> {t('openAPISchemaManagement.testError')}
                    <pre>{testError}</pre>
                  </div>
                )}
                {response && (
                  <div className="response-section">
                    <h4>{t('openAPISchemaManagement.responseTitle')}</h4>
                    <pre>{JSON.stringify(response, null, 2)}</pre>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenAPISchemaManagement;
