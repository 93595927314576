import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaBox, FaShoppingCart, FaComments, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import './LogisticsDashboard.css';
import FloatingLogisticsChatbot from './FloatingLogisticsChatbot';

const LogisticsDashboard = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState({ orders: [], total: 0, page: 1, page_size: 50, pages: 1 });
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [activeTab, setActiveTab] = useState('orders');
  const navigate = useNavigate();

  const fetchOrders = useCallback(async (page = 1, sort = 'created_at', order = 'desc') => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`/api/logistics/logistics/orders?page=${page}&page_size=50&sort=${sort}&order=${order}`, { headers });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError(t('logisticsDashboard.fetchOrdersError'));
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = sessionStorage.getItem('access_token');
        const headers = { Authorization: `Bearer ${token}` };
        const [ordersRes, productsRes, conversationsRes] = await Promise.all([
          fetchOrders(),
          axios.get('/api/logistics/logistics/products', { headers }),
          axios.get('/api/logistics/logistician-conversations', { headers }),
        ]);
        setProducts(productsRes.data.products);
        setConversations(conversationsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(t('logisticsDashboard.fetchDataError'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fetchOrders, t]);

  const handleSortChange = (field) => {
    const newOrder = field === sortField && sortOrder === 'desc' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(newOrder);
    fetchOrders(orders.page, field, newOrder);
  };

  const handlePageChange = (newPage) => {
    fetchOrders(newPage, sortField, sortOrder);
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  if (isLoading) return <div className="loading">{t('common.loading')}</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="logistics-dashboard-container">
      <h2 className="page-title">{t('logisticsDashboard.title')}</h2>
      
      <div className="logistics-dashboard-grid">
        <div className="logistics-dashboard-card">
          <h3><FaComments /> {t('logisticsDashboard.conversationsTitle')}</h3>
          <div className="logistics-table-responsive">
            <table className="logistics-table">
              <thead>
                <tr>
                  <th>{t('logisticsDashboard.conversationWith')}</th>
                  <th>{t('logisticsDashboard.lastMessage')}</th>
                  <th>{t('logisticsDashboard.updatedAt')}</th>
                  <th>{t('logisticsDashboard.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {conversations.map((conversation) => (
                  <tr key={conversation._id}>
                    <td>{conversation.conversation_with}</td>
                    <td>{conversation.last_message}</td>
                    <td>{new Date(conversation.updated_at).toLocaleString()}</td>
                    <td>
                      <button className="logistics-btn logistics-btn-view" onClick={() => navigate(`/conversation/${conversation._id}`)}>
                        {t('logisticsDashboard.viewDetails')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="logistics-dashboard-card">
          <div className="logistics-tabs">
            <button 
              className={`logistics-tab-btn ${activeTab === 'orders' ? 'active' : ''}`}
              onClick={() => setActiveTab('orders')}
            >
              <FaShoppingCart /> {t('logisticsDashboard.ordersTitle')}
            </button>
            <button 
              className={`logistics-tab-btn ${activeTab === 'products' ? 'active' : ''}`}
              onClick={() => setActiveTab('products')}
            >
              <FaBox /> {t('logisticsDashboard.productsTitle')}
            </button>
          </div>
          
          {activeTab === 'orders' && (
            <div className="logistics-table-responsive">
              <table className="logistics-table">
                <thead>
                  <tr>
                    <th onClick={() => handleSortChange('reference')} className="sortable">
                      {t('logisticsDashboard.orderReference')} <SortIcon field="reference" />
                    </th>
                    <th onClick={() => handleSortChange('status')} className="sortable">
                      {t('logisticsDashboard.orderStatus')} <SortIcon field="status" />
                    </th>
                    <th onClick={() => handleSortChange('grand_total')} className="sortable">
                      {t('logisticsDashboard.orderTotal')} <SortIcon field="grand_total" />
                    </th>
                    <th onClick={() => handleSortChange('created_at')} className="sortable">
                      {t('logisticsDashboard.orderDate')} <SortIcon field="created_at" />
                    </th>
                    <th>{t('logisticsDashboard.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.orders.map((order) => (
                    <tr key={order.order_id}>
                      <td>{order.reference}</td>
                      <td><span className={`logistics-status-badge ${order.status}`}>{order.status}</span></td>
                      <td>{order.grand_total}</td>
                      <td>{new Date(order.created_at).toLocaleString()}</td>
                      <td>
                        <button className="logistics-btn logistics-btn-view" onClick={() => navigate(`/order-logistics/${order.reference}`)}>
                          {t('logisticsDashboard.viewDetails')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <button 
                  onClick={() => handlePageChange(orders.page - 1)} 
                  disabled={orders.page === 1}
                >
                  {t('common.previous')}
                </button>
                <span>{t('common.page')} {orders.page} {t('common.of')} {orders.pages}</span>
                <button 
                  onClick={() => handlePageChange(orders.page + 1)} 
                  disabled={orders.page === orders.pages}
                >
                  {t('common.next')}
                </button>
              </div>
            </div>
          )}
  
          {activeTab === 'products' && (
            <div className="logistics-table-responsive">
              <table className="logistics-table">
                <thead>
                  <tr>
                    <th>{t('logisticsDashboard.productName')}</th>
                    <th>{t('logisticsDashboard.productPrice')}</th>
                    <th>{t('logisticsDashboard.productEAN')}</th>
                    <th>{t('logisticsDashboard.productWeight')}</th>
                    <th>{t('logisticsDashboard.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td>{product.price}</td>
                      <td>{product.ean}</td>
                      <td>{product.weight}</td>
                      <td>
                        <button className="logistics-btn logistics-btn-view" onClick={() => navigate(`/product/${product.id}`)}>
                          {t('logisticsDashboard.viewDetails')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <FloatingLogisticsChatbot />
    </div>
  );
};

export default LogisticsDashboard;