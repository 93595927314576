import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaBox, FaShoppingCart, FaComments, FaClipboardList, FaSort, FaSortUp, FaSortDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Logistician.css';
import FloatingChatbotLogistic from './FloatingChatbotLogistic';

const Logistician = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState({ items: [], total: 0, page: 1, size: 50, pages: 1 });
  const [conversations, setConversations] = useState([]);
  const [pickingTickets, setPickingTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [sortField, setSortField] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [activeTab, setActiveTab] = useState('orders');
  const navigate = useNavigate();

  const fetchOrders = useCallback(async (page = 1, field = 'created_at', order = 'desc') => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`/api/logistics/user_orders?page=${page}&size=50&sort=${field}&order=${order}`, { headers });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setError(t('logistician.fetchOrdersError') + ': ' + (error.response?.data?.detail || error.message));
    } finally {
      setIsLoading(false);
    }
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const token = sessionStorage.getItem('access_token');
        const headers = { Authorization: `Bearer ${token}` };
        const [productsRes, ordersRes, conversationsRes, pickingTicketsRes] = await Promise.all([
          axios.get('/api/logistics/user_products', { headers }),
          axios.get('/api/logistics/user_orders?page=1&size=50&sort=created_at&order=desc', { headers }),
          axios.get('/api/logistics/logistician-conversations', { headers }),
          axios.get('/api/logistics/picking-tickets', { headers }),
        ]);
        setProducts(productsRes.data);
        setOrders(ordersRes.data);
        setConversations(conversationsRes.data);
        setPickingTickets(pickingTicketsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        setError(t('logistician.fetchDataError') + ': ' + (error.response?.data?.detail || error.message));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [t]);

  const handleEdit = (product) => {
    setEditingProduct({ ...product });
  };

  const handleSave = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('/api/logistics/update_product', editingProduct, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEditingProduct(null);
      const updatedProducts = await axios.get('/api/logistics/user_products', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(updatedProducts.data);
    } catch (error) {
      console.error('Error updating product:', error);
      setError(t('logistician.updateProductError'));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingProduct({ ...editingProduct, [name]: value });
  };

  const handleSortChange = (field) => {
    const newOrder = field === sortField && sortOrder === 'desc' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(newOrder);
    fetchOrders(1, field, newOrder);
  };

  const handlePageChange = (newPage) => {
    fetchOrders(newPage, sortField, sortOrder);
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  if (isLoading) return <div className="loading">{t('common.loading')}</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="logistician-container">
      <h2 className="page-title">{t('logistician.title')}</h2>
      
      <div className="logistician-dashboard-grid">
        <div className="logistician-dashboard-card">
          <h3><FaClipboardList /> {t('logistician.pickingTicketsTitle')}</h3>
          <div className="logistician-table-responsive">
            <table className="logistician-table">
              <thead>
                <tr>
                  <th>{t('logistician.orderReference')}</th>
                  <th>{t('logistician.customerEmail')}</th>
                  <th>{t('logistician.subject')}</th>
                  <th>{t('logistician.createdAt')}</th>
                  <th>{t('logistician.status')}</th>
                  <th>{t('logistician.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {pickingTickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <td>{ticket.order_reference}</td>
                    <td>{ticket.customer_email}</td>
                    <td>{ticket.subject}</td>
                    <td>{new Date(ticket.created_at).toLocaleString()}</td>
                    <td><span className={`logistician-status-badge ${ticket.status_update_result || 'pending'}`}>
                      {ticket.status_update_result || t('logistician.pending')}
                    </span></td>
                    <td>
                      {/* Add actions if needed */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="logistician-dashboard-card">
          <h3><FaComments /> {t('logistician.conversationsTitle')}</h3>
          <div className="logistician-table-responsive">
            <table className="logistician-table">
              <thead>
                <tr>
                  <th>{t('logistician.conversationWith')}</th>
                  <th>{t('logistician.lastMessage')}</th>
                  <th>{t('logistician.updatedAt')}</th>
                  <th>{t('logistician.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {conversations.map((conversation) => (
                  <tr key={conversation._id}>
                    <td>{conversation.conversation_with}</td>
                    <td>{conversation.last_message}</td>
                    <td>{new Date(conversation.updated_at).toLocaleString()}</td>
                    <td>
                      <button className="logistician-btn logistician-btn-view" onClick={() => navigate(`/conversation/${conversation._id}`)}>
                        {t('logistician.viewDetails')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="logistician-dashboard-card">
          <div className="logistician-tabs">
            <button 
              className={`logistician-tab-btn ${activeTab === 'orders' ? 'active' : ''}`}
              onClick={() => setActiveTab('orders')}
            >
              <FaShoppingCart /> {t('logistician.ordersTitle')}
            </button>
            <button 
              className={`logistician-tab-btn ${activeTab === 'products' ? 'active' : ''}`}
              onClick={() => setActiveTab('products')}
            >
              <FaBox /> {t('logistician.productsTitle')}
            </button>
          </div>
          
          {activeTab === 'orders' && (
            <div className="logistician-table-responsive">
              <table className="logistician-table">
                <thead>
                  <tr>
                    <th onClick={() => handleSortChange('reference')} className="sortable">
                      {t('logistician.orderReference')} <SortIcon field="reference" />
                    </th>
                    <th onClick={() => handleSortChange('status')} className="sortable">
                      {t('logistician.orderStatus')} <SortIcon field="status" />
                    </th>
                    <th onClick={() => handleSortChange('grand_total')} className="sortable">
                      {t('logistician.orderTotal')} <SortIcon field="grand_total" />
                    </th>
                    <th onClick={() => handleSortChange('created_at')} className="sortable">
                      {t('logistician.orderDate')} <SortIcon field="created_at" />
                    </th>
                    <th>{t('logistician.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.items && orders.items.length > 0 ? (
                    orders.items.map((order) => (
                      <tr key={order.order_id}>
                        <td>{order.reference}</td>
                        <td><span className={`logistician-status-badge ${order.status}`}>{order.status}</span></td>
                        <td>{order.grand_total}</td>
                        <td>{new Date(order.created_at).toLocaleString()}</td>
                        <td>
                          <button className="logistician-btn logistician-btn-view" onClick={() => navigate(`/order/${order.reference}`)}>
                            {t('logistician.viewDetails')}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">{t('logistician.noOrdersFound')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination-controls">
                <button 
                  onClick={() => handlePageChange(orders.page - 1)}
                  disabled={orders.page === 1}
                >
                  <FaChevronLeft /> {t('common.previous')}
                </button>
                <span>{t('common.page')} {orders.page} {t('common.of')} {orders.pages}</span>
                <button 
                  onClick={() => handlePageChange(orders.page + 1)}
                  disabled={orders.page === orders.pages}
                >
                  {t('common.next')} <FaChevronRight />
                </button>
              </div>
            </div>
          )}
  
          {activeTab === 'products' && (
            <div className="logistician-table-responsive">
              <table className="logistician-table">
                <thead>
                  <tr>
                    <th>{t('logistician.productName')}</th>
                    <th>{t('logistician.productPrice')}</th>
                    <th>{t('logistician.productEAN')}</th>
                    <th>{t('logistician.productWeight')}</th>
                    <th>{t('logistician.productDetails')}</th>
                    <th>{t('logistician.productTechnicalDetails')}</th>
                    <th>{t('logistician.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      {editingProduct && editingProduct.id === product.id ? (
                        <>
                          <td><input className="logistician-input" name="name" value={editingProduct.name} onChange={handleInputChange} /></td>
                          <td><input className="logistician-input" name="price" type="number" value={editingProduct.price} onChange={handleInputChange} /></td>
                          <td><input className="logistician-input" name="ean" value={editingProduct.ean} onChange={handleInputChange} /></td>
                          <td><input className="logistician-input" name="weight" type="number" value={editingProduct.weight} onChange={handleInputChange} /></td>
                          <td><textarea className="logistician-input" name="details" value={editingProduct.details} onChange={handleInputChange} /></td>
                          <td><textarea className="logistician-input" name="technical_details" value={editingProduct.technical_details} onChange={handleInputChange} /></td>
                          <td>
                            <button className="logistician-btn logistician-btn-save" onClick={handleSave}>{t('logistician.save')}</button>
                            <button className="logistician-btn logistician-btn-cancel" onClick={() => setEditingProduct(null)}>{t('logistician.cancel')}</button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{product.name}</td>
                          <td>{product.price}</td>
                          <td>{product.ean}</td>
                          <td>{product.weight}</td>
                          <td>{product.details}</td>
                          <td>{product.technical_details}</td>
                          <td>
                            <button className="logistician-btn logistician-btn-edit" onClick={() => handleEdit(product)}>{t('logistician.edit')}</button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <FloatingChatbotLogistic />
      </div>
    </div>
  );
};

export default Logistician;