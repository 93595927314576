import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Payment.css';

const Payment = () => {
  const [user, setUser] = useState({
    subscription_plan: '',
    subscription_end_date: '',
    plan_type: 'month',
    email: '',
  });
  const [billingFrequency, setBillingFrequency] = useState('monthly');
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState('');
  const [selectedUpgradePlan, setSelectedUpgradePlan] = useState(null);

  const subscriptionTiers = {
    basic: {
      name: 'Basic Plan',
      priceMonthly: '99 €',
      priceYearly: '990 €',
      priceIdMonthly: 'price_1PKiVpDTCxT09HPmb7Oac0n3',
      priceIdYearly: 'price_1PKiXSDTCxT09HPm21UcEPM9',
      urlMonthly: 'https://buy.stripe.com/3cs14z6iecgy9Z69AE',
      urlYearly: 'https://buy.stripe.com/cN28x14a66Wegnu4gl',
      features: [
        'Basic automated customer support (inquiries, order information, delivery tracking)',
        'Integration with one communication channel',
        'Centralized request dashboard',
        'Basic data visualization tools',
        '500 tickets per month',
        'Complete multilingual support (over 100 languages)',
        '99.9% AI uptime',
        'Customer support available for any type of business (e-commerce and non-e-commerce)',
        'AI response personalization',
        'Ability to handle demand spikes without quality impact',
      ],
    },
    standard: {
      name: 'Standard Plan',
      priceMonthly: '149 €',
      priceYearly: '1430 €',
      priceIdMonthly: 'price_1PKiYxDTCxT09HPmXBN2elGf',
      priceIdYearly: 'price_1PKiaTDTCxT09HPmCZZyCvi2',
      urlMonthly: 'https://buy.stripe.com/cN214zdKG6We2wE6or',
      urlYearly: 'https://buy.stripe.com/00gcNhayu1BU0ow6oq',
      features: [
        'All Basic Plan features',
        'Integration with up to 3 communication channels',
        'Archiving and transformation of resolved tickets',
        'Access to AI Agent logs',
        'Advanced data visualization tools',
        '2,000 tickets per month',
      ],
    },
    professional: {
      name: 'Professional',
      priceMonthly: '299 €',
      priceYearly: '2865 €',
      priceIdMonthly: 'price_1PKibGDTCxT09HPmmezxamVC',
      priceIdYearly: 'price_1PKicNDTCxT09HPmfNlFPfZy',
      urlMonthly: 'https://buy.stripe.com/7sI3cH4a694m8V28wx',
      urlYearly: 'https://buy.stripe.com/4gw6oT9uqeoGdbi000',
      features: [
        'All Standard Plan features',
        'Integration with up to 5 communication channels',
        'Form creation and management',
        '5,000 tickets per month',
      ],
    },
    enterprise: {
      name: 'Enterprise Plan',
      priceMonthly: 'On Request from 599 €',
      priceYearly: 'On Request from 5750 €',
      urlMonthly: 'https://example.com/enterprise-monthly',
      urlYearly: 'https://example.com/enterprise-yearly',
      features: [
        'All Professional Plan features',
        'Integration with all available communication channels',
        'API connection for large online stores',
        'Customer satisfaction analysis and advanced reports',
        'Product and service performance analysis',
        'Recurring issue and request analysis',
        'Real-time audio conversation',
        'Dedicated 24/7 technical support',
        'Unlimited tickets',
        'Complete AI customization to reflect company culture',
      ],
    },
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (!token) {
        alert('You are not logged in.');
        return;
      }

      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setUser({
          subscription_plan: response.data.subscription_plan || '',
          subscription_end_date: response.data.subscription_end_date || '',
          plan_type: response.data.plan_type || 'month',
          email: response.data.email || '',
        });
        setBillingFrequency(response.data.plan_type === 'year' ? 'yearly' : 'monthly');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const handleRedirectToCheckout = (url) => {
    if (!user.email) {
      alert('Email not found. Please make sure you are logged in.');
      return;
    }
    const checkoutUrl = `${url}?prefilled_email=${user.email}`;
    window.location.href = checkoutUrl;
  };

  const toggleBillingFrequency = () => {
    setBillingFrequency((prev) => (prev === 'monthly' ? 'yearly' : 'monthly'));
  };

  const getSubscriptionUrl = (tier) => {
    return billingFrequency === 'monthly' ? tier.urlMonthly : tier.urlYearly;
  };

  const getSubscriptionPrice = (tier) => {
    return billingFrequency === 'monthly' ? tier.priceMonthly : tier.priceYearly;
  };

  const getPriceId = (tier) => {
    return billingFrequency === 'monthly' ? tier.priceIdMonthly : tier.priceIdYearly;
  };

  const getCurrentTier = () => {
    return Object.keys(subscriptionTiers).find(
      (key) => subscriptionTiers[key].name.toLowerCase() === user.subscription_plan.toLowerCase()
    );
  };

  const renderSubscriptionOptions = () => {
    const currentTierKey = getCurrentTier();
    if (!currentTierKey) return null;

    const currentTierIndex = Object.keys(subscriptionTiers).indexOf(currentTierKey);
    const upgradeOptions = Object.keys(subscriptionTiers).slice(currentTierIndex + 1);
    const downgradeOptions = Object.keys(subscriptionTiers).slice(0, currentTierIndex);

    return (
      <>
        <div className="current-plan">
          <h3>Current Plan: <span>{subscriptionTiers[currentTierKey].name}</span></h3>
          <p>{getSubscriptionPrice(subscriptionTiers[currentTierKey])} {billingFrequency}</p>
          <p>Next Payment: {new Date(user.subscription_end_date).toLocaleDateString()}</p>
          <ul className="features-list">
            <h2>Features of current plan:</h2>
            {subscriptionTiers[currentTierKey].features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
        <div className="billing-frequency">
          <label className="switch">
            <input type="checkbox" checked={billingFrequency === 'yearly'} onChange={toggleBillingFrequency} />
            <span className="slider round"></span>
          </label>
          <span>{billingFrequency === 'monthly' ? 'Monthly' : 'Yearly'}</span>
        </div>
        <div className="upgrade-options">
          <div className="subscription-tiers">
            {upgradeOptions.map((key) => {
              const tier = subscriptionTiers[key];
              return (
                <div key={key} className={`subscription-tier ${tier.mostChosen ? 'most-chosen' : ''}`}>
                  {tier.mostChosen && <div className="most-chosen-title">Most Chosen</div>}
                  <h3>{tier.name} - {getSubscriptionPrice(tier)}</h3>
                  <ul className="features-list">
                    {tier.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        setSelectedUpgradePlan(tier);
                        setShowUpgradeDialog(true);
                      }}
                      className="checkout-button"
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="downgrade-options">
          <h3>Downgrade Options</h3>
          <div className="subscription-tiers">
            {downgradeOptions.map((key) => {
              const tier = subscriptionTiers[key];
              return (
                <div key={key} className="subscription-tier">
                  <h3>{tier.name} - {getSubscriptionPrice(tier)}</h3>
                  <ul className="features-list">
                    {tier.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        setSelectedUpgradePlan(tier);
                        setShowUpgradeDialog(true);
                      }}
                      className="checkout-button"
                      >
                      Downgrade
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const handleUpgradeSubscription = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const priceId = getPriceId(selectedUpgradePlan);
      const response = await axios.post(
        '/api//upgrade-subscription',
        { price_id: priceId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        alert('Subscription upgraded successfully.');
        fetchUserData();
        setShowUpgradeDialog(false);
      } else {
        alert('Error upgrading subscription.');
      }
    } catch (error) {
      console.error('Failed to upgrade subscription:', error);
      alert('Error upgrading subscription.');
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (cancelConfirmation !== 'cancel my subscription') {
        alert('You need to type "cancel my subscription" to confirm.');
        return;
      }

      await axios.delete('/api/cancel-subscription', {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Subscription canceled successfully.');
      fetchUserData();
      setShowCancelDialog(false);
      setCancelConfirmation('');
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      alert('Error canceling subscription.');
    }
  };

  return (
    <div className="payment-container">
      <div className="subscription-plan">
        <h2>Subscription Plan</h2>
        <div className="billing-frequency">
          <label className="switch">
            <input type="checkbox" checked={billingFrequency === 'yearly'} onChange={toggleBillingFrequency} />
            <span className="slider round"></span>
          </label>
          <span>{billingFrequency === 'monthly' ? 'Monthly' : 'Yearly'}</span>
        </div>
        {user.subscription_plan === 'free' ? (
          <div className="subscription-tiers">
            {Object.keys(subscriptionTiers).map((key) => {
              const tier = subscriptionTiers[key];
              return (
                <div key={key} className={`subscription-tier ${tier.mostChosen ? 'most-chosen' : ''}`}>
                  {tier.mostChosen && <div className="most-chosen-title">Most Chosen</div>}
                  <h3>{tier.name} - {getSubscriptionPrice(tier)}</h3>
                  <ul className="features-list">
                    {tier.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <div className="button-container">
                    <button
                      onClick={() => handleRedirectToCheckout(getSubscriptionUrl(tier))}
                      className="checkout-button"
                    >
                      Select
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {renderSubscriptionOptions()}
            <button
              onClick={() => setShowCancelDialog(true)}
              className="cancel-subscription-button"
            >
              Cancel Subscription
            </button>
          </>
        )}
      </div>

      {showCancelDialog && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setShowCancelDialog(false)}>&times;</span>
            <h3>Confirm Cancellation</h3>
            <p>To confirm cancellation, type "cancel my subscription" below:</p>
            <input
              type="text"
              value={cancelConfirmation}
              onChange={(e) => setCancelConfirmation(e.target.value)}
              className="cancel-confirmation-input"
            />
            <div className="modal-buttons">
              <button onClick={handleCancelSubscription} className="confirm-cancel-button">
                Confirm
              </button>
              <button onClick={() => setShowCancelDialog(false)} className="cancel-dialog-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showUpgradeDialog && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setShowUpgradeDialog(false)}>&times;</span>
            <h3>Confirm Upgrade</h3>
            <p>Are you sure you want to upgrade to the {selectedUpgradePlan.name} plan?</p>
            <div className="modal-buttons">
              <button onClick={handleUpgradeSubscription} className="confirm-upgrade-button">
                Confirm
              </button>
              <button onClick={() => setShowUpgradeDialog(false)} className="cancel-dialog-close-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
