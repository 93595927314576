import React from 'react';
import './InfoPanel.css';
import { useTranslation } from 'react-i18next';
import { FaUser, FaShoppingCart, FaStickyNote } from 'react-icons/fa';

const InfoPanel = ({ clientInfo, orderDetails, summary }) => {
    const { t } = useTranslation();

    const extractCustomerInfo = (orderDetails) => {
        const lines = orderDetails.split('\n');
        const customerInfo = {};
        ['Customer Email', 'Customer Name', 'Customer Phone'].forEach(field => {
            const line = lines.find(l => l.startsWith(field));
            if (line) {
                const [key, value] = line.split(':');
                customerInfo[key.trim()] = value.trim();
            }
        });
        return customerInfo;
    };

    const renderCustomerInfo = () => {
        let info = {};
        if (clientInfo) {
            info = clientInfo.split('\n').reduce((acc, line) => {
                const [key, value] = line.split(':');
                if (key && value) {
                    acc[key.trim()] = value.trim();
                }
                return acc;
            }, {});
        } else if (orderDetails) {
            info = extractCustomerInfo(orderDetails);
        }

        return (
            <div className="client-info-grid">
                {Object.entries(info).map(([key, value]) => (
                    <div key={key} className="info-item">
                        <span className="info-label">{key}:</span>
                        <span className="info-value">{value}</span>
                    </div>
                ))}
            </div>
        );
    };

    const renderOrderDetails = () => {
        if (!orderDetails) return null;

        const customerInfoFields = ['Customer Email', 'Customer Name', 'Customer Phone'];
        const details = orderDetails.trim().split('\n');
        return details.filter(line => !customerInfoFields.some(field => line.startsWith(field)))
            .map((line, index) => {
                if (line.includes(':')) {
                    const [key, value] = line.split(':');
                    return (
                        <div key={index} className="order-detail-item">
                            <span className="detail-label">{key.trim()}:</span>
                            <span className="detail-value">{value.trim()}</span>
                        </div>
                    );
                }
                return <div key={index} className="order-detail-header">{line}</div>;
            });
    };

    return (
        <div className="info-panel">
            <div className="info-section">
                <h2><FaUser /> {t('infoPanel.clientInformation')}</h2>
                {renderCustomerInfo()}
            </div>
            <div className="info-section">
                <h2><FaShoppingCart /> {t('infoPanel.orderDetails')}</h2>
                <div className="order-details-container">
                    {renderOrderDetails()}
                </div>
            </div>
            <div className="info-section">
                <h2><FaStickyNote /> {t('infoPanel.summary')}</h2>
                <p className="summary-content">{summary}</p>
            </div>
        </div>
    );
};

export default InfoPanel;