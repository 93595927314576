// src/components/CustomerList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaSync,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaExclamationTriangle,
  FaChevronLeft,
  FaChevronRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';
import './CustomerList.css'; // Ensure this CSS file includes the styles below

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [syncError, setSyncError] = useState('');
  const [isSyncing, setIsSyncing] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 20;
  const totalPages = Math.ceil(customers.length / customersPerPage);

  useEffect(() => {
    fetchCustomers();
    // Reset to first page when customers data changes (e.g., after sync)
    setCurrentPage(1);
  }, []);

  const fetchCustomers = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/customers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError(t('customerList.fetchError'));
    }
  };

  const handleSync = async () => {
    setIsSyncing(true);
    setSyncError('');
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/customers_sync/sync', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomers(response.data);
      setIsSyncing(false);
      setCurrentPage(1); // Reset to first page after sync
    } catch (error) {
      console.error('Error syncing customers:', error);
      setSyncError(t('customerList.syncError'));
      setIsSyncing(false);
    }
  };

  // Get current customers for the page
  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = customers.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  // Pagination Logic: Display Previous, Next, First, Last, and up to 4 rolling page numbers
  const getPageNumbers = () => {
    const pages = [];

    // Always show the first page
    if (currentPage > 2) {
      pages.push(1);
      if (currentPage > 3) {
        pages.push('ellipsis-start');
      }
    }

    // Determine the start and end of the rolling window
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 2);

    // Adjust if near the start or end
    if (currentPage === 1) {
      endPage = Math.min(totalPages, 4);
    } else if (currentPage === totalPages) {
      startPage = Math.max(1, totalPages - 3);
    }

    for (let i = startPage; i <= endPage; i++) {
      // Avoid duplicating first and last pages
      if (i !== 1 && i !== totalPages) {
        pages.push(i);
      }
    }

    // Always show the last page
    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        pages.push('ellipsis-end');
      }
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  const handlePageChange = (pageNumber) => {
    // Handle ellipsis clicks (if implemented as non-clickable, ignore)
    if (pageNumber === 'ellipsis-start' || pageNumber === 'ellipsis-end') {
      return;
    }
    // Ensure the page number is within bounds
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (error) {
    return (
      <div className="dashboard-container">
        <div className="error-message">
          <FaExclamationTriangle /> {error}
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-card">
        <h2>
          <FaUser /> {t('customerList.title')}
        </h2>
        <div className="customer-list-actions">
          <button
            onClick={handleSync}
            disabled={isSyncing}
            className="sync-button"
          >
            <FaSync className={isSyncing ? 'syncing' : ''} />
            {isSyncing ? t('customerList.syncing') : t('customerList.syncButton')}
          </button>
          {syncError && (
            <div className="error-message">
              <FaExclamationTriangle /> {syncError}
            </div>
          )}
        </div>
        {customers.length === 0 ? (
          <div className="no-customers">
            <FaUser /> {t('customerList.noCustomers')}
          </div>
        ) : (
          <>
            <table className="customer-table">
              <thead>
                <tr>
                  <th>
                    {t('customerList.name')} <FaUser />
                  </th>
                  <th>
                    {t('customerList.email')} <FaEnvelope />
                  </th>
                  <th>
                    {t('customerList.phone')} <FaPhone />
                  </th>
                  <th>{t('customerList.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentCustomers.map((customer) => (
                  <tr
                    key={customer._id}
                    onClick={() => navigate(`/customers/${customer._id}`)}
                    className="clickable-row"
                  >
                    <td>{customer.name}</td>
                    <td>{customer.email}</td>
                    <td>{customer.phone_number}</td>
                    <td>
                      {/* Placeholder for future actions */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                <FaChevronLeft /> {t('pagination.previous')}
              </button>

              {/* Page Numbers */}
              {pageNumbers.map((number, index) => {
                if (number === 'ellipsis-start' || number === 'ellipsis-end') {
                  return (
                    <span key={index} className="pagination-ellipsis">
                      &hellip;
                    </span>
                  );
                }
                return (
                  <button
                    key={index}
                    onClick={() => handlePageChange(number)}
                    className={`pagination-button ${
                      currentPage === number ? 'active' : ''
                    }`}
                  >
                    {number}
                  </button>
                );
              })}

              {/* Next Button */}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                {t('pagination.next')} <FaChevronRight />
              </button>

              {/* Last Button */}
              {totalPages > 1 && (
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  className="pagination-button"
                >
                  <FaAngleDoubleRight /> {t('pagination.last')}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerList;
