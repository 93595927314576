import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import FloatingChatbotLogistic from './FloatingChatbotLogistic';

const OrderDetails = () => {
  const { t } = useTranslation();
  const { reference } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrderDetails();
  }, [reference]);

  const fetchOrderDetails = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/logistics/order/${reference}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setOrder(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching order details:', error);
      setError(t('orderDetailsWarehouse.fetchError'));
      setIsLoading(false);
    }
  };

  const fetchTrackingInfo = async () => {
    if (!order.tracking_number) {
      setTrackingInfo({ status: "No tracking info", tracking_events: [] });
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/logistics/tracking/${order.tracking_number}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTrackingInfo(response.data);
    } catch (error) {
      console.error('Error fetching tracking info:', error);
      setTrackingInfo({ status: "No tracking info", tracking_events: [] });
    }
  };

  if (isLoading) return <div>{t('orderDetailsWarehouse.loading')}</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!order) return <div>{t('orderDetailsWarehouse.noOrderFound')}</div>;

  return (
    <div className="order-details">
      <h2>{t('orderDetailsWarehouse.title')}</h2>
      <button onClick={() => navigate(-1)}>{t('orderDetailsWarehouse.back')}</button>
      
      <div className="order-info">
        <p><strong>{t('orderDetailsWarehouse.reference')}:</strong> {order.reference}</p>
        <p><strong>{t('orderDetailsWarehouse.status')}:</strong> {order.status}</p>
        <p><strong>{t('orderDetailsWarehouse.total')}:</strong> {order.grand_total}</p>
        <p><strong>{t('orderDetailsWarehouse.createdAt')}:</strong> {new Date(order.created_at).toLocaleString()}</p>
        <p><strong>{t('orderDetailsWarehouse.trackingNumber')}:</strong> {order.tracking_number || t('orderDetailsWarehouse.noTrackingNumber')}</p>
      </div>

      <div className="tracking-info">
        <h3>{t('orderDetailsWarehouse.trackingInfo')}</h3>
        {trackingInfo ? (
          <div>
            <p><strong>{t('orderDetailsWarehouse.trackingStatus')}:</strong> {trackingInfo.status}</p>
            {trackingInfo.status !== "No tracking info" && (
              <>
                <h4>{t('orderDetailsWarehouse.trackingEvents')}</h4>
                <ul>
                  {trackingInfo.tracking_events.map((event, index) => (
                    <li key={index}>
                      <p>{new Date(event.date).toLocaleString()}</p>
                      <p>{event.location}</p>
                      <p>{event.status}</p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ) : (
          <button onClick={fetchTrackingInfo}>{t('orderDetailsWarehouse.fetchTracking')}</button>
        )}
      </div>

      <h3>{t('orderDetailsWarehouse.shippingAddress')}</h3>
      <div className="shipping-address">
        <p>{order.shipping_address.firstname} {order.shipping_address.lastname}</p>
        <p>{order.shipping_address.email}</p>
        <p>{order.shipping_address.street1}</p>
        {order.shipping_address.street2 && <p>{order.shipping_address.street2}</p>}
        <p>{order.shipping_address.postcode} {order.shipping_address.city}</p>
        <p>{order.shipping_address.country}</p>
      </div>

      <h3>{t('orderDetailsWarehouse.items')}</h3>
      <table className="items-table">
        <thead>
          <tr>
            <th>{t('orderDetailsWarehouse.itemName')}</th>
            <th>{t('orderDetailsWarehouse.itemQuantity')}</th>
            <th>{t('orderDetailsWarehouse.itemPrice')}</th>
            <th>{t('orderDetailsWarehouse.itemTotal')}</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>{item.price}</td>
              <td>{item.quantity * item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <FloatingChatbotLogistic />
    </div>
  );
};

export default OrderDetails;