import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Bar, Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
} from 'chart.js';
import {
  FaBoxOpen,
  FaWarehouse,
  FaChartBar,
  FaDollarSign,
  FaClock,
  FaPercentage,
  FaUserTie,
} from 'react-icons/fa';
import './LogisticsStatisticsPanel.css';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement
);

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8884d8',
  '#82ca9d',
  '#ffc658',
];

const LogisticsStatisticsPanel = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/logistics/statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStats(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching logistics statistics:', error);
      setError(t('logisticsStatistics.fetchError'));
      setIsLoading(false);
    }
  };

  if (isLoading)
    return <div className="loading">{t('logisticsStatistics.loading')}</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!stats)
    return (
      <div className="error-message">{t('logisticsStatistics.noStatsFound')}</div>
    );

  // Prepare data for charts
  const ordersByStatusData = {
    labels: stats.orders_by_status.map(
      (item) => `${t('logisticsStatistics.status')} ${item.status}`
    ),
    datasets: [
      {
        data: stats.orders_by_status.map((item) => item.count),
        backgroundColor: COLORS,
      },
    ],
  };

  const topProductsData = {
    labels: stats.top_products.map((item) => item.name),
    datasets: [
      {
        label: t('logisticsStatistics.totalQuantity'),
        data: stats.top_products.map((item) => item.total_quantity || 0),
        backgroundColor: '#8884d8',
      },
      {
        label: t('logisticsStatistics.stockLevel'),
        data: stats.top_products.map((item) => item.stock_level || 0),
        backgroundColor: '#82ca9d',
      },
    ],
  };

  const topCustomersData = {
    labels: stats.top_customers.map((item) => item.customer_name),
    datasets: [
      {
        label: t('logisticsStatistics.totalSpent'),
        data: stats.top_customers.map((item) => item.total_spent || 0),
        backgroundColor: '#0088FE',
      },
    ],
  };

  const performanceOverTimeData = {
    labels: stats.orders_by_status.map(
      (item) => `${t('logisticsStatistics.status')} ${item.status}`
    ),
    datasets: [
      {
        label: t('logisticsStatistics.orderCount'),
        data: stats.orders_by_status.map((item) => item.count),
        fill: false,
        borderColor: '#8884d8',
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="logistics-statistics-panel">
      <h2 className="panel-title">
        <FaChartBar className="icon" />
        {t('logisticsStatistics.title')}
      </h2>

      {/* Stat Cards */}
      <div className="stat-cards">
        <StatCard
          title={t('logisticsStatistics.totalOrders')}
          value={(stats.total_orders || 0).toLocaleString()}
          icon={<FaBoxOpen />}
        />
        <StatCard
          title={t('logisticsStatistics.totalRevenue')}
          value={`$${(stats.total_revenue || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
          icon={<FaDollarSign />}
        />
        <StatCard
          title={t('logisticsStatistics.averageOrderValue')}
          value={`$${(stats.average_order_value || 0).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`}
          icon={<FaDollarSign />}
        />
        <StatCard
          title={t('logisticsStatistics.returnRate')}
          value={`${(stats.return_rate || 0).toFixed(2)}%`}
          icon={<FaPercentage />}
        />
        <StatCard
          title={t('logisticsStatistics.inventoryTurnoverRate')}
          value={
            stats.inventory_turnover_rate
              ? stats.inventory_turnover_rate.toFixed(2)
              : 'N/A'
          }
          icon={<FaChartBar />}
        />
        <StatCard
          title={t('logisticsStatistics.stockoutRate')}
          value={`${(stats.stockout_rate || 0).toFixed(2)}%`}
          icon={<FaWarehouse />}
        />
        <StatCard
          title={t('logisticsStatistics.averageProcessingTime')}
          value={`${(stats.average_order_processing_time || 0).toFixed(2)} ${t(
            'logisticsStatistics.hours'
          )}`}
          icon={<FaClock />}
        />
        <StatCard
          title={t('logisticsStatistics.warehouseUtilization')}
          value={`${(stats.warehouse_stats.utilization_rate || 0).toFixed(2)}%`}
          icon={<FaWarehouse />}
        />
      </div>

      {/* Charts */}
      <div className="chart-grid">
        <ChartCard title={t('logisticsStatistics.ordersByStatus')}>
          <Pie
            data={ordersByStatusData}
            options={{ maintainAspectRatio: false, responsive: true }}
          />
        </ChartCard>
        <ChartCard title={t('logisticsStatistics.topProducts')}>
          <Bar
            data={topProductsData}
            options={{ maintainAspectRatio: false, responsive: true }}
          />
        </ChartCard>
        <ChartCard title={t('logisticsStatistics.topCustomers')}>
          <Bar
            data={topCustomersData}
            options={{ maintainAspectRatio: false, responsive: true }}
          />
        </ChartCard>
        <ChartCard title={t('logisticsStatistics.performanceOverTime')}>
          <Line
            data={performanceOverTimeData}
            options={{ maintainAspectRatio: false, responsive: true }}
          />
        </ChartCard>
      </div>

      {/* Picking and Warehouse Statistics */}
      <div className="stats-grid">
        <StatsCard
          title={t('logisticsStatistics.pickingStatistics')}
          stats={[
            {
              label: t('logisticsStatistics.totalPickings'),
              value: stats.picking_stats.total_pickings || 0,
            },
            {
              label: t('logisticsStatistics.completedPickings'),
              value: stats.picking_stats.completed_pickings || 0,
            },
            {
              label: t('logisticsStatistics.inProgressPickings'),
              value: stats.picking_stats.in_progress_pickings || 0,
            },
            {
              label: t('logisticsStatistics.averagePickingTime'),
              value: `${(stats.picking_stats.average_picking_time || 0).toFixed(
                2
              )} ${t('logisticsStatistics.minutes')}`,
            },
          ]}
        />
        <StatsCard
          title={t('logisticsStatistics.warehouseStatistics')}
          stats={[
            {
              label: t('logisticsStatistics.totalLocations'),
              value: stats.warehouse_stats.total_locations || 0,
            },
            {
              label: t('logisticsStatistics.occupiedLocations'),
              value: stats.warehouse_stats.occupied_locations || 0,
            },
            {
              label: t('logisticsStatistics.utilizationRate'),
              value: `${(stats.warehouse_stats.utilization_rate || 0).toFixed(
                2
              )}%`,
            },
          ]}
        />
      </div>

      {/* Inventory Statistics */}
      <div className="inventory-stats">
        <h3 className="section-title">
          {t('logisticsStatistics.inventoryStatistics')}
        </h3>
        <p className="total-value">
          <strong>{t('logisticsStatistics.totalInventoryValue')}:</strong>{' '}
          $
          {(stats.total_inventory_value || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>

        {/* Low Stock Products */}
        <h4 className="subsection-title">
          {t('logisticsStatistics.lowStockProducts')}
        </h4>
        <div className="table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>{t('logisticsStatistics.sku')}</th>
                <th>{t('logisticsStatistics.name')}</th>
                <th>{t('logisticsStatistics.stockLevel')}</th>
                <th>{t('logisticsStatistics.totalValue')}</th>
              </tr>
            </thead>
            <tbody>
              {stats.low_stock_products.map((product, index) => (
                <tr key={index}>
                  <td>{product.sku}</td>
                  <td>{product.name}</td>
                  <td>{product.stock_level || 0}</td>
                  <td>${(product.total_value || 0).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Dead Stock Products */}
        <h4 className="subsection-title">
          {t('logisticsStatistics.deadStockProducts')}
        </h4>
        <div className="table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>{t('logisticsStatistics.sku')}</th>
                <th>{t('logisticsStatistics.name')}</th>
                <th>{t('logisticsStatistics.stockLevel')}</th>
                <th>{t('logisticsStatistics.totalValue')}</th>
              </tr>
            </thead>
            <tbody>
              {stats.dead_stock_products.map((product, index) => (
                <tr key={index}>
                  <td>{product.sku}</td>
                  <td>{product.name}</td>
                  <td>{product.stock_level || 0}</td>
                  <td>${(product.total_value || 0).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Top Customers */}
      <div className="customers-stats">
        <h3 className="section-title">{t('logisticsStatistics.topCustomers')}</h3>
        <div className="table-container">
          <table className="customers-table">
            <thead>
              <tr>
                <th>{t('logisticsStatistics.customerName')}</th>
                <th>{t('logisticsStatistics.email')}</th>
                <th>{t('logisticsStatistics.totalSpent')}</th>
              </tr>
            </thead>
            <tbody>
              {stats.top_customers.map((customer, index) => (
                <tr key={index}>
                  <td>{customer.customer_name}</td>
                  <td>{customer.email}</td>
                  <td>${(customer.total_spent || 0).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Components for reusability
const StatCard = ({ title, value, icon }) => (
  <div className="stat-card">
    <div className="stat-header">
      <span className="stat-title">{title}</span>
      <span className="stat-icon">{icon}</span>
    </div>
    <div className="stat-value">{value}</div>
  </div>
);

const ChartCard = ({ title, children }) => (
  <div className="chart-card">
    <h3 className="chart-title">{title}</h3>
    <div className="chart-container">{children}</div>
  </div>
);

const StatsCard = ({ title, stats }) => (
  <div className="stats-card">
    <h3 className="stats-title">{title}</h3>
    <div className="stats-list">
      {stats.map((stat, index) => (
        <div key={index} className="stat-item">
          <span className="stat-label">{stat.label}</span>
          <span className="stat-value">{stat.value}</span>
        </div>
      ))}
    </div>
  </div>
);

export default LogisticsStatisticsPanel;