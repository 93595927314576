import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const ShopifyCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);

  const addLog = (message) => {
    setLogs(prevLogs => [...prevLogs, `${new Date().toISOString()}: ${message}`]);
    console.log(message);
  };

  useEffect(() => {
    const handleShopifyLogin = async () => {
      addLog('ShopifyCallback component mounted');
      addLog(`Current URL: ${window.location.href}`);

      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      addLog(`Token: ${token ? 'present' : 'missing'}`);

      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          addLog(`Decoded token: ${JSON.stringify(decodedToken)}`);

          sessionStorage.setItem('access_token', token);
          // You can still store the shop if needed, but get it from the token
          if (decodedToken.shop) {
            sessionStorage.setItem('shop', decodedToken.shop);
          }

          addLog('Attempting to verify token');
          const response = await axios.get('/api/users/user', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          addLog(`Token verification response: ${JSON.stringify(response.data)}`);

          addLog('Token verified successfully, redirecting to dashboard');
          navigate('/dashboard');
        } catch (error) {
          addLog(`Error: ${error.message}`);
          if (error.response) {
            addLog(`Error response: ${JSON.stringify(error.response.data)}`);
          }
          addLog('Redirecting to login page due to verification failure');
          navigate('/');
        }
      } else {
        addLog('Token missing, redirecting to login page');
        navigate('/');
      }
    };

    handleShopifyLogin();
  }, [location, navigate]);

  return (
    <div>
      <p>Processing Shopify login...</p>
      <div style={{whiteSpace: 'pre-wrap'}}>
        {logs.join('\n')}
      </div>
    </div>
  );
};

export default ShopifyCallback;