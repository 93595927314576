import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaComments, FaTimes, FaPaperPlane, FaFileAlt, FaTrash, FaDownload } from 'react-icons/fa';
import './FloatingLogisticsChatbot.css';

const FloatingLogisticsChatbot = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [includePageContent, setIncludePageContent] = useState(false);
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      fetchConversationHistory();
    }
  }, [isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchConversationHistory = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/logistics-edn-agent/conversations', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessages(response.data[0]?.messages || []);
    } catch (error) {
      console.error('Error fetching conversation history:', error);
    }
  };

  const getPageContent = () => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = document.body.innerHTML;
    const scripts = tempDiv.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const sendMessage = async () => {
    if (!inputMessage.trim()) return;

    setIsSending(true);
    try {
      const token = sessionStorage.getItem('access_token');
      let messageToSend = inputMessage;
      if (includePageContent) {
        const pageContent = getPageContent();
        messageToSend = `Page Content: ${pageContent}\n\nUser Query: ${inputMessage}`;
      }

      const response = await axios.post('/api/logistics/logistics-edn-agent/message', 
        { message: messageToSend },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessages([...messages, 
        { content: inputMessage, sender: 'user', timestamp: new Date() },
        { content: response.data.response, sender: 'ai', timestamp: new Date() }
      ]);
      setInputMessage('');
      setIncludePageContent(false);
    } catch (error) {
      console.error('Error sending message to Logistics EDN Agent:', error);
    } finally {
      setIsSending(false);
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const deleteConversation = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/logistics/logistics-edn-agent/conversation', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessages([]);
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const renderMessageContent = (content) => {
    const lines = content.split('\n');
    return lines.map((line, index) => {
      if (line.startsWith('- **')) {
        const [key, value] = line.split(':** ');
        return (
          <div key={index} className="message-detail">
            <strong>{key.replace('- **', '')}:</strong> {value}
          </div>
        );
      } else if (line.includes('[DOWNLOAD]')) {
        const match = line.match(/\[DOWNLOAD\]\((.*?)\)/);
        if (match) {
          const url = match[1];
          return (
            <div key={index} className="download-link">
              <a href={url} target="_blank" rel="noopener noreferrer">
                <FaDownload /> Download Export
              </a>
            </div>
          );
        }
      }
      return <p key={index}>{line}</p>;
    });
  };

  return (
    <div className={`floating-logistics-chatbot ${isOpen ? 'open' : ''}`}>
      {!isOpen ? (
        <button className="chatbot-toggle" onClick={() => setIsOpen(true)} aria-label={t('logisticsChatbot.openChat')}>
          <FaComments />
        </button>
      ) : (
        <div className="chatbot-window">
          <div className="chatbot-header">
            <h3>{t('logisticsChatbot.title')}</h3>
            <button className="delete-btn" onClick={deleteConversation} aria-label={t('logisticsChatbot.deleteConversation')}>
              <FaTrash />
            </button>
            <button className="close-btn" onClick={() => setIsOpen(false)} aria-label={t('logisticsChatbot.closeChat')}>
              <FaTimes />
            </button>
          </div>
          <div className="chatbot-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {renderMessageContent(message.content)}
                <small>{new Date(message.timestamp).toLocaleString()}</small>
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>
          <div className="chatbot-input">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={t('logisticsChatbot.inputPlaceholder')}
              disabled={isSending}
            />
            <button 
              className={`include-content-btn ${includePageContent ? 'active' : ''}`}
              onClick={() => setIncludePageContent(!includePageContent)} 
              aria-label={t('logisticsChatbot.includePageContent')}
            >
              <FaFileAlt />
            </button>
            <button onClick={sendMessage} disabled={isSending} aria-label={t('logisticsChatbot.sendMessage')}>
              {isSending ? t('logisticsChatbot.sending') : <FaPaperPlane />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingLogisticsChatbot;