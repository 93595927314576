// ChatbotPreview.js
import React from 'react';
import './ChatbotPreview.css'; // Create a CSS file for styling

const ChatbotPreview = ({
  primary_color,
  secondary_color,
  tertiary_color,
  company_name,
  agent_name,
  greetings_message,
}) => {
  const primaryTextColor = getContrastColor(primary_color);
  const secondaryTextColor = getContrastColor(secondary_color);

  return (
    <div className="chatbot-preview-container">
      <div
        className="chatbot-header"
        style={{ backgroundColor: primary_color, color: primaryTextColor }}
      >
        <span>{company_name}</span>
        <button
          className="chatbot-close-button"
          style={{ color: primaryTextColor }}
        >
          ×
        </button>
      </div>
      <div className="chatbot-messages">
        <div
          className="chatbot-message bot"
          style={{ backgroundColor: secondary_color, color: secondaryTextColor }}
        >
          <div className="chatbot-message-sender">
            {agent_name || 'Bot'}
          </div>
          <div className="chatbot-message-text">{greetings_message}</div>
        </div>
      </div>
      <div className="chatbot-input-container">
        <textarea
          className="chatbot-input"
          placeholder="Type a message..."
          readOnly
        />
        <button
          className="chatbot-send-button"
          style={{ backgroundColor: primary_color, color: primaryTextColor }}
          disabled
        >
          ➤
        </button>
      </div>
    </div>
  );
};

function getContrastColor(hexcolor) {
  // Remove leading '#' if present
  if (hexcolor[0] === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // Convert hex color to RGB
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Calculate luminance
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black or white depending on luminance
  return yiq >= 128 ? 'black' : 'white';
}

export default ChatbotPreview;