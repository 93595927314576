// src/components/KnowledgeBoard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './KnowledgeBoard.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaFile,
  FaPlus,
  FaTrash,
  FaEye,
  FaCheckCircle,
  FaTimesCircle,
  FaSpinner,
  FaExclamationTriangle,
  FaTimes
} from 'react-icons/fa';

const KnowledgeBoard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // States
  const [user, setUser] = useState(undefined);
  const [resources, setResources] = useState([]); // All resources
  const [fileUrl, setFileUrl] = useState('');
  const [overlay, setOverlay] = useState({ show: false, message: '', success: false });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [uploadStatuses, setUploadStatuses] = useState({});
  const [urlFileUploadStatus, setUrlFileUploadStatus] = useState(null); // State for URL file upload

  // Fetch user data on component mount
  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/'); 
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  // Redirect if user data fetch fails
  useEffect(() => {
    if (user === null) {
      navigate('/'); 
    }
  }, [user, navigate]);

  // Fetch resources once user is loaded
  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchUserResources(token);
    }
  }, [user]);

  // Function to fetch user data
  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  // Function to fetch all user resources
  const fetchUserResources = async (token) => {
    try {
      console.log('Current origin:', window.location.origin);
      console.log('Making request to:', '/api/files/list');
      const response = await axios.get('/api/files/list', {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setResources(response.data.files);
    } catch (error) {
      console.error('Full error object:', error);
      console.error('Request config:', error.config);
      alert(t('knowledgeBoard.fetchResourcesError'));
    }
  };

  // Handle file selection and upload
  const handleFileSelect = async (event) => {
    const selectedFiles = event.target.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      alert(t('knowledgeBoard.selectFiles'));
      return;
    }

    // Initialize upload statuses
    const initialStatuses = {};
    for (let i = 0; i < selectedFiles.length; i++) {
      initialStatuses[selectedFiles[i].name] = { uploading: true, success: false };
    }
    setUploadStatuses((prevStatuses) => ({ ...prevStatuses, ...initialStatuses }));

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post('/api/files/upload/', formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });

      // Update upload statuses based on response
      const updatedStatuses = { ...uploadStatuses };
      for (const fileResult of response.data.files) {
        if (fileResult.status === 'success') {
          updatedStatuses[fileResult.filename] = { uploading: false, success: true };
        } else {
          updatedStatuses[fileResult.filename] = { uploading: false, success: false, error: fileResult.message };
        }
      }
      setUploadStatuses(updatedStatuses);
      fetchUserResources(token);
    } catch (error) {
      console.error('Upload failed:', error);
      // Set all upload statuses to failed
      const updatedStatuses = { ...uploadStatuses };
      for (let i = 0; i < selectedFiles.length; i++) {
        updatedStatuses[selectedFiles[i].name] = { uploading: false, success: false, error: t('knowledgeBoard.uploadFailed') };
      }
      setUploadStatuses(updatedStatuses);
    }
  };

  // Handle URL file upload
  const handleUrlFileSelect = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      alert(t('knowledgeBoard.selectUrlFile'));
      return;
    }

    setUrlFileUploadStatus({ uploading: true, success: false });

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post('/api/files/upload-url-file', formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });

      setUrlFileUploadStatus({ uploading: false, success: true, message: response.data.message });
      fetchUserResources(token); // Update resources list
    } catch (error) {
      console.error('Upload failed:', error);
      setUrlFileUploadStatus({ uploading: false, success: false, error: t('knowledgeBoard.uploadFailed') });
    }
  };

  // Handle resource deletion
  const handleDeleteResource = async (resourceKey) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/files/delete/?file_key=${encodeURIComponent(resourceKey)}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert(t('knowledgeBoard.deleteSuccess'));
      fetchUserResources(token);
    } catch (error) {
      console.error('Failed to delete resource:', error);
      alert(t('knowledgeBoard.deleteError'));
    }
  };

  // Handle resource viewing
  const fetchAndViewResource = async (resourceKey) => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/files/generate-view-url/?file_key=${resourceKey}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFileUrl(response.data.url);
    } catch (error) {
      console.error('Failed to view resource:', error);
      alert(t('knowledgeBoard.viewResourceError'));
    }
  };

  // Update resources via modal
  const updateResources = async (title, url) => {
    setOverlay({ show: true, message: t('knowledgeBoard.updating'), success: false });

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/files/update-web-json-2/?title=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setOverlay({ show: true, message: t('knowledgeBoard.updateSuccess'), success: true });
      fetchUserResources(token);
    } catch (error) {
      console.error('Failed to update resources:', error);
      setOverlay({ show: true, message: t('knowledgeBoard.updateError'), success: false });
    } finally {
      setTimeout(() => setOverlay({ show: false, message: '', success: false }), 2000);
    }
  };

  // Open update modal
  const handleOpenUpdateModal = () => setShowUpdateModal(true);

  // Submit update modal
  const handleUpdateModalSubmit = async () => {
    if (newTitle && newUrl) {
      await updateResources(newTitle, newUrl);
      setNewTitle('');
      setNewUrl('');
      setShowUpdateModal(false);
    } else {
      alert(t('knowledgeBoard.fillAllFields'));
    }
  };

  // Close update modal
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setNewTitle('');
    setNewUrl('');
  };

  // Remove resource entry
  const handleRemoveResourceEntry = async (title) => {
    const confirmRemove = window.confirm(t('knowledgeBoard.confirmRemoveResource'));
    if (!confirmRemove) return;

    const updatedResources = resources.filter((entry) => entry.title !== title);
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/files/delete-web-json-2/?title=${encodeURIComponent(title)}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setResources(updatedResources);
      alert(t('knowledgeBoard.removeResourceSuccess'));
    } catch (error) {
      console.error('Failed to remove resource entry:', error);
      alert(t('knowledgeBoard.removeResourceError'));
    }
  };

  // Render based on user state
  if (user === undefined) {
    return (
      <div className="dashboard-container">
        <div className="loading-spinner">
          <FaSpinner className="spinner" /> {t('knowledgeBoard.loading')}
        </div>
      </div>
    );
  }

  if (user === null) {
    return (
      <div className="dashboard-container">
        <div className="error-message">
          <FaExclamationTriangle /> {t('knowledgeBoard.userFetchError')}
        </div>
      </div>
    );
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="dashboard-container">
        <div className="dashboard-card knowledge-board-no-access">
          <FaExclamationTriangle className="error-icon" />
          <p>{t('knowledgeBoard.noAccessMessage')}</p>
          <button
            onClick={() => navigate('/Payment')}
            className="upgrade-button"
            aria-label={t('knowledgeBoard.upgradeNow')}
          >
            {t('knowledgeBoard.upgradeNow')}
          </button>
        </div>
      </div>
    );
  }

  // Main render
  return (
    <div className="dashboard-container">
      <h3 className="section-title"><FaFile /> {t('knowledgeBoard.title')}</h3>
      <div className="dashboard-card knowledge-board-container">
        <div className="knowledge-board-content">
          {/* Left Column: Resources */}
          <div className="resources-section">
            <h4><FaFile /> {t('knowledgeBoard.resources')}</h4>
            <div className="resources-list">
              {resources.map((resource) => (
                <div key={resource._id} className="resource-item">
                  <div className="resource-info">
                    <span
                      className="resource-title"
                      onClick={() => fetchAndViewResource(resource._id)}
                      title={resource.description || ''}
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') fetchAndViewResource(resource._id);
                      }}
                      role="button"
                    >
                      {resource.title || resource.name}
                    </span>
                    {resource.url && (
                      <a href={resource.url} target="_blank" rel="noopener noreferrer" className="resource-url">
                        {resource.url}
                      </a>
                    )}
                  </div>
                  <button
                    className="icon-button delete-button"
                    onClick={() => handleRemoveResourceEntry(resource.title || resource.name)}
                    aria-label={t('knowledgeBoard.deleteResourceAriaLabel', { resource: resource.title || resource.name })}
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Right Column: Upload Options */}
          <div className="upload-options-section">
            <h4><FaPlus /> {t('knowledgeBoard.uploadOptions')}</h4>
            <div className="upload-section">
              {/* Upload Files */}
              <div className="file-upload">
                <input
                  id="fileInput"
                  type="file"
                  onChange={handleFileSelect}
                  accept=".pdf,.doc,.docx,.txt"
                  multiple
                  style={{ display: 'none' }}
                />
                <button
                  className="secondary-button upload-button"
                  onClick={() => document.getElementById('fileInput').click()}
                  aria-label={t('knowledgeBoard.uploadFilesAriaLabel')}
                >
                  <FaPlus /> {t('knowledgeBoard.uploadFiles')}
                </button>
              </div>

              {/* Upload URL File */}
              <div className="url-file-upload">
                <input
                  id="urlFileInput"
                  type="file"
                  onChange={handleUrlFileSelect}
                  accept=".txt"
                  style={{ display: 'none' }}
                />
                <button
                  className="secondary-button upload-url-file-button"
                  onClick={() => document.getElementById('urlFileInput').click()}
                  aria-label={t('knowledgeBoard.uploadUrlFileAriaLabel')}
                >
                  <FaPlus /> {t('knowledgeBoard.uploadUrlFile')}
                </button>
              </div>
            </div>

            {/* Add Resource Button */}
            <button
              className="secondary-button add-resource-button"
              onClick={handleOpenUpdateModal}
              aria-label={t('knowledgeBoard.addResourceAriaLabel')}
            >
              <FaPlus /> {t('knowledgeBoard.addResource')}
            </button>

            {/* Upload Statuses */}
            <div className="upload-statuses">
              {Object.keys(uploadStatuses).map((filename) => (
                <div key={filename} className="upload-status-item">
                  <span>{filename}</span>
                  {uploadStatuses[filename].uploading && (
                    <span><FaSpinner className="spinning-icon" /> {t('knowledgeBoard.uploading')}</span>
                  )}
                  {!uploadStatuses[filename].uploading && uploadStatuses[filename].success && (
                    <span><FaCheckCircle className="success-icon" /> {t('knowledgeBoard.uploadSuccess')}</span>
                  )}
                  {!uploadStatuses[filename].uploading && !uploadStatuses[filename].success && (
                    <span><FaTimesCircle className="error-icon" /> {t('knowledgeBoard.uploadError')}: {uploadStatuses[filename].error}</span>
                  )}
                </div>
              ))}
            </div>

            {/* URL File Upload Status */}
            {urlFileUploadStatus && (
              <div className="upload-status-item">
                {urlFileUploadStatus.uploading && (
                  <span><FaSpinner className="spinning-icon" /> {t('knowledgeBoard.uploading')}</span>
                )}
                {!urlFileUploadStatus.uploading && urlFileUploadStatus.success && (
                  <span><FaCheckCircle className="success-icon" /> {t('knowledgeBoard.uploadSuccess')}: {urlFileUploadStatus.message}</span>
                )}
                {!urlFileUploadStatus.uploading && !urlFileUploadStatus.success && (
                  <span><FaTimesCircle className="error-icon" /> {t('knowledgeBoard.uploadError')}: {urlFileUploadStatus.error}</span>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Update Resource Modal */}
        {showUpdateModal && (
          <div className="modalweb" role="dialog" aria-modal="true">
            <div className="modal-content">
              <button className="close-popup-button" onClick={handleUpdateModalClose} aria-label={t('knowledgeBoard.closeModal')}>
                <FaTimes />
              </button>
              <h3>{t('knowledgeBoard.addNewResource')}</h3>
              <div className="form-group">
                <label htmlFor="newTitle">{t('knowledgeBoard.title')} <span className="required">*</span></label>
                <input
                  type="text"
                  id="newTitle"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  aria-required="true"
                  placeholder={t('knowledgeBoard.enterTitle')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="newUrl">{t('knowledgeBoard.url')} <span className="required">*</span></label>
                <input
                  type="text"
                  id="newUrl"
                  value={newUrl}
                  onChange={(e) => setNewUrl(e.target.value)}
                  aria-required="true"
                  placeholder={t('knowledgeBoard.enterUrl')}
                />
              </div>
              <div className="modal-buttons">
                <button
                  className="primary-button submit-button"
                  onClick={handleUpdateModalSubmit}
                  aria-label={t('knowledgeBoard.submit')}
                >
                  {t('knowledgeBoard.submit')}
                </button>
                <button
                  className="secondary-button cancel-button"
                  onClick={handleUpdateModalClose}
                  aria-label={t('knowledgeBoard.cancel')}
                >
                  <FaTimes /> {t('knowledgeBoard.cancel')}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Overlay for Feedback */}
        {overlay.show && (
          <div className="overlay">
            <div className={`overlay-content ${overlay.success ? 'success' : 'error'}`}>
              {overlay.success ? <FaCheckCircle /> : <FaTimesCircle />}
              <p>{overlay.message}</p>
            </div>
          </div>
        )}

        {/* Resource Viewer Modal */}
        {fileUrl && (
          <div className="file-viewer-modal" role="dialog" aria-modal="true">
            <div className="file-viewer-content">
              <button className="close-popup-button" onClick={() => setFileUrl('')} aria-label={t('knowledgeBoard.closeViewer')}>
                <FaTimes />
              </button>
              <iframe src={fileUrl} title="Resource Viewer" className="file-viewer-iframe"></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowledgeBoard;
