import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaBox, FaShoppingCart, FaTruck, FaTag } from 'react-icons/fa';
import FloatingLogisticsChatbot from './FloatingLogisticsChatbot';
import './LogisticsOrderDetails.css';

const LogisticsOrderDetails = () => {
  const { t } = useTranslation();
  const { reference } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrderDetails();
  }, [reference]);

  const fetchOrderDetails = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/logistics/logistics/order/${reference}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setOrder(response.data);
      console.log("Order details:", response.data); // Debugging: Check the contents of the order
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching order details:', error);
      setError(t('logisticsOrderDetails.fetchError'));
      setIsLoading(false);
    }
  };

  const fetchTrackingInfo = async () => {
    console.log("Fetching tracking info...");

    // Extract tracking number from the labels array
    const trackingNumber = order?.labels?.[0]?.tracking;

    if (!trackingNumber) {
      console.log("No tracking number available");
      setTrackingInfo({ status: "No tracking info", tracking_events: [] });
      return;
    }

    console.log("Tracking number:", trackingNumber);

    try {
      const token = sessionStorage.getItem('access_token');
      console.log("Token:", token);

      const response = await axios.get(`/api/logistics/tracking/${trackingNumber}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log("Tracking info response:", response.data);
      setTrackingInfo(response.data);
    } catch (error) {
      console.error('Error fetching tracking info:', error);
      setTrackingInfo({ status: "No tracking info", tracking_events: [] });
    }
  };

  // Optionally auto-fetch tracking info when the order state is set
  useEffect(() => {
    if (order && order.labels && order.labels.length > 0) {
      fetchTrackingInfo();
    }
  }, [order]);

  if (isLoading) return <div className="loading">{t('logisticsOrderDetails.loading')}</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!order) return <div className="error-message">{t('logisticsOrderDetails.noOrderFound')}</div>;

  return (
    <div className="logistics-order-details">
      <h2 className="page-title">
        <FaShoppingCart /> {t('logisticsOrderDetails.title')}
      </h2>
      <button className="back-button" onClick={() => navigate(-1)}>
        <FaArrowLeft /> {t('logisticsOrderDetails.back')}
      </button>
      
      <div className="order-info">
        <h3><FaTag /> {t('logisticsOrderDetails.orderInfo')}</h3>
        <p><strong>{t('logisticsOrderDetails.reference')}:</strong> {order.reference}</p>
        <p><strong>{t('logisticsOrderDetails.status')}:</strong> <span className={`status-badge status-${order.status}`}>{order.status}</span></p>
        <p><strong>{t('logisticsOrderDetails.siteId')}:</strong> {order.site_id}</p>
        <p><strong>{t('logisticsOrderDetails.total')}:</strong> {order.grand_total} {order.currency}</p>
        <p><strong>{t('logisticsOrderDetails.shippingCost')}:</strong> {order.shipping_cost} {order.currency}</p>
        <p><strong>{t('logisticsOrderDetails.createdAt')}:</strong> {new Date(order.created_at).toLocaleString()}</p>
        <p><strong>{t('logisticsOrderDetails.orderedAt')}:</strong> {order.ordered_at ? new Date(order.ordered_at).toLocaleString() : t('logisticsOrderDetails.notAvailable')}</p>
        <p><strong>{t('logisticsOrderDetails.shippedAt')}:</strong> {order.shipped_at ? new Date(order.shipped_at).toLocaleString() : t('logisticsOrderDetails.notShippedYet')}</p>
        <p><strong>{t('logisticsOrderDetails.carrier')}:</strong> {order.carrier_name} (ID: {order.carrier_id})</p>
        <p><strong>{t('logisticsOrderDetails.packages')}:</strong> {order.num_packages}</p>
        <p><strong>{t('logisticsOrderDetails.totalWeight')}:</strong> {order.total_weight}kg</p>
        {order.comment && <p><strong>{t('logisticsOrderDetails.comment')}:</strong> {order.comment}</p>}
      </div>

      <div className="shipping-address">
        <h3><FaTruck /> {t('logisticsOrderDetails.shippingAddress')}</h3>
        <p>{order.shipping_address.company}</p>
        <p>{order.shipping_address.firstname} {order.shipping_address.lastname}</p>
        <p>{order.shipping_address.street1}</p>
        {order.shipping_address.street2 && <p>{order.shipping_address.street2}</p>}
        <p>{order.shipping_address.postcode} {order.shipping_address.city}</p>
        <p>{order.shipping_address.country}</p>
        <p>{order.shipping_address.telephone}</p>
        <p>{order.shipping_address.email}</p>
      </div>

      <div className="order-items">
        <h3><FaBox /> {t('logisticsOrderDetails.items')}</h3>
        <table className="items-table">
          <thead>
            <tr>
              <th>{t('logisticsOrderDetails.itemName')}</th>
              <th>{t('logisticsOrderDetails.itemSku')}</th>
              <th>{t('logisticsOrderDetails.itemQuantity')}</th>
              <th>{t('logisticsOrderDetails.itemPrice')}</th>
              <th>{t('logisticsOrderDetails.itemTotal')}</th>
              <th>{t('logisticsOrderDetails.itemWeight')}</th>
              <th>{t('logisticsOrderDetails.itemEan')}</th>
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.sku}</td>
                <td>{item.quantity}</td>
                <td>{item.price} {order.currency}</td>
                <td>{(item.quantity * item.price).toFixed(2)} {order.currency}</td>
                <td>{item.weight ? `${item.weight}kg` : t('logisticsOrderDetails.notAvailable')}</td>
                <td>{item.ean || t('logisticsOrderDetails.notAvailable')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="tracking-info">
        <h3>{t('logisticsOrderDetails.trackingInfo')}</h3>
        {trackingInfo ? (
          <div>
            <p><strong>{t('logisticsOrderDetails.trackingStatus')}:</strong> {trackingInfo.status}</p>
            {trackingInfo.status !== "No tracking info" && (
              <>
                <h4>{t('logisticsOrderDetails.trackingEvents')}</h4>
                <ul>
                  {trackingInfo.tracking_events.map((event, index) => (
                    <li key={index}>
                      <p>{new Date(event.date).toLocaleString()}</p>
                      <p>{event.location}</p>
                      <p>{event.status}</p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ) : (
          <button onClick={fetchTrackingInfo}>{t('logisticsOrderDetails.fetchTracking')}</button>
        )}
      </div>

      <FloatingLogisticsChatbot />
    </div>
  );
};

export default LogisticsOrderDetails;
