import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import TicketTableDashboard from './TicketTableDashboard';
import { useTranslation } from 'react-i18next';
import { FaTicketAlt, FaRobot, FaUserClock, FaChartLine } from 'react-icons/fa';

const DashboardLogistics = () => {
    const [user, setUser] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState({ tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
    const [ticketStats, setTicketStats] = useState({
        automated_tickets: 0,
        pending_tickets: 0
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        wsRef.current = new WebSocket(`/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            console.log('Close code:', event.code);
            console.log('Close reason:', event.reason);
            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                fetchTickets(token);
            }
        };
    };

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            navigate('/');
            return;
        }

        fetchUserData(token);
        fetchTickets(token);
        fetchMonthlyUsage(token);
        fetchTicketStats(token);
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            setUser(null);
        }
    };

    const fetchMonthlyUsage = async (token) => {
        try {
            const response = await axios.get('/api/users/monthly-usage', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMonthlyUsage(response.data || { tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
        } catch (error) {
            console.error('Failed to fetch monthly usage:', error);
        }
    };

    const fetchTickets = async (token) => {
        try {
            const response = await axios.get('/api/tickets/logistics-tickets', {  // Changed to logistics-specific endpoint
                headers: { Authorization: `Bearer ${token}` }
            });
            setTickets(response.data || []);
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };

    const fetchTicketStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTicketStats(response.data || {
                automated_tickets: 0,
                pending_tickets: 0
            });
        } catch (error) {
            console.error('Failed to fetch ticket stats:', error);
        }
    };

    const handleTicketClick = async (ticketId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/status`, null, {
                headers: { Authorization: `Bearer ${token}` }
            });
            navigate(`/tickets/${ticketId}/messages`);
        } catch (error) {
            console.error('Failed to update ticket status:', error);
        }
    };

    const usagePercentage = monthlyUsage.ticket_limit > 0 ? (monthlyUsage.tickets_used / monthlyUsage.ticket_limit) * 100 : 0;

    return (
        <div className="dashboard-container">
            <div className="dashboard-grid">
                <div className="dashboard-card tickets-overview">
                    <h3><FaTicketAlt /> {t('ticketsOverview')}</h3>
                    <div className="card-content">
                        <div className="stat-item">
                            <span className="stat-label">{t('remainingTickets')}</span>
                            <span className="stat-value">{ticketStats.remaining_tickets}</span>
                        </div>
                        <div className="stat-item">
                            <span className="stat-label">{t('automatedTickets')}</span>
                            <span className="stat-value automated">{ticketStats.automated_tickets}</span>
                        </div>
                        <div className="stat-item">
                            <span className="stat-label">{t('assignedTickets')}</span>
                            <span className="stat-value assigned">{ticketStats.pending_tickets}</span>
                        </div>
                    </div>
                </div>

                <div className="dashboard-card ticket-usage">
                    <h3><FaChartLine /> {t('ticketUsage')}</h3>
                    <div className="card-content">
                        <div className="usage-bar">
                            <div 
                                className="usage-progress" 
                                style={{width: `${usagePercentage}%`}}
                            ></div>
                        </div>
                        <p className="usage-text">{monthlyUsage.tickets_used}/{monthlyUsage.ticket_limit} {t('ticketsUsed')}</p>
                        <p className="extra-tickets"><strong>{t('remainingExtraTickets')}:</strong> {monthlyUsage.remaining_extra_tickets}</p>
                    </div>
                </div>

                <div className="dashboard-card ai-agent-info">
                    <h3><FaRobot /> {t('aiAgentInfo')}</h3>
                    <div className="card-content">
                        <div className="agent-info-item">
                            <span className="info-label">{t('aiAgentName')}</span>
                            <span className="info-value">{user?.ai_agent_name || t('notAvailable')}</span>
                        </div>
                        <div className="agent-info-item">
                            <span className="info-label">{t('dashboardAutomationStatus')}</span>
                            <span className="info-value">{user?.automation || t('notAvailable')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <h3><FaUserClock /> {t('automatedAndPendingTickets')}</h3>
            <TicketTableDashboard tickets={tickets} user={user} onTicketClick={handleTicketClick} />
        </div>
    );
};

export default DashboardLogistics;
