// src/components/CustomerDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaArrowLeft,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaHome,
  FaBoxOpen,
  FaTicketAlt,
  FaFileAlt,
  FaExclamationTriangle,
  FaSpinner,
} from 'react-icons/fa';
import './CustomerDetails.css';

const CustomerDetails = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const fetchCustomerData = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem('access_token');
      const [customerRes, ticketsRes] = await Promise.all([
        axios.get(`/api/tickets/customers/${customerId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/tickets/customers/${customerId}/tickets`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setCustomer(customerRes.data);
      setTickets(ticketsRes.data);
    } catch (err) {
      console.error('Error fetching customer data:', err);
      setError(t('customerDetails.fetchError'));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="dashboard-container">
        <div className="loading-spinner">
          <FaSpinner className="spinner" /> {t('customerDetails.loading')}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-container">
        <div className="error-message">
          <FaExclamationTriangle /> {error}
        </div>
      </div>
    );
  }

  if (!customer) {
    return null; // Optionally, handle this case differently
  }

  const { name, email, phone_number, address, orders, files } = customer;

  return (
    <div className="dashboard-container">
      <div className="dashboard-card">
        <button className="back-button" onClick={() => navigate(-1)}>
          <FaArrowLeft /> {t('customerDetails.back')}
        </button>
        <h2>
          <FaUser /> {t('customerDetails.title')}
        </h2>
        <div className="customer-info">
          <div className="info-item">
            <FaUser className="info-icon" />
            <div>
              <strong>{t('customerDetails.name')}:</strong>
              <span>{name}</span>
            </div>
          </div>
          <div className="info-item">
            <FaEnvelope className="info-icon" />
            <div>
              <strong>{t('customerDetails.email')}:</strong>
              <span>{email}</span>
            </div>
          </div>
          <div className="info-item">
            <FaPhone className="info-icon" />
            <div>
              <strong>{t('customerDetails.phone')}:</strong>
              <span>{phone_number}</span>
            </div>
          </div>
          {address && (
            <div className="info-item">
              <FaHome className="info-icon" />
              <div>
                <strong>{t('customerDetails.address')}:</strong>
                <span>
                  {address.street1}
                  {address.street2 && `, ${address.street2}`}
                </span>
                <span>
                  {address.postcode} {address.city}
                </span>
                <span>{address.country}</span>
              </div>
            </div>
          )}
        </div>

        {/* Orders Section */}
        <div className="section">
          <h3>
            <FaBoxOpen /> {t('customerDetails.orders')}
          </h3>
          {orders && orders.length > 0 ? (
            <table className="details-table">
              <thead>
                <tr>
                  <th>{t('customerDetails.orderReference')}</th>
                  <th>{t('customerDetails.orderStatus')}</th>
                  <th>{t('customerDetails.orderTotal')}</th>
                  <th>{t('customerDetails.orderDate')}</th>
                  <th>{t('customerDetails.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr
                    key={order.order_id}
                    onClick={() => navigate(`/order/${order.reference}`)}
                    className="clickable-row"
                  >
                    <td>{order.reference}</td>
                    <td>{order.status}</td>
                    <td>{order.grand_total}</td>
                    <td>{new Date(order.created_at).toLocaleDateString()}</td>
                    <td>
                      <button
                        className="view-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/order/${order.reference}`);
                        }}
                        aria-label={t('customerDetails.viewOrder')}
                      >
                        {t('customerDetails.viewOrder')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t('customerDetails.noOrders')}</p>
          )}
        </div>

        {/* Tickets Section */}
        <div className="section">
          <h3>
            <FaTicketAlt /> {t('customerDetails.tickets')}
          </h3>
          {tickets && tickets.length > 0 ? (
            <table className="details-table">
              <thead>
                <tr>
                  <th>{t('customerDetails.ticketSubject')}</th>
                  <th>{t('customerDetails.ticketStatus')}</th>
                  <th>{t('customerDetails.ticketDate')}</th>
                  <th>{t('customerDetails.channel')}</th>
                  <th>{t('customerDetails.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => (
                  <tr
                    key={ticket._id}
                    onClick={() => navigate(`/tickets/${ticket._id}`)}
                    className="clickable-row"
                  >
                    <td>{ticket.subject}</td>
                    <td>{ticket.status}</td>
                    <td>{new Date(ticket.created_at).toLocaleDateString()}</td>
                    <td>{ticket.channel_name}</td>
                    <td>
                      <button
                        className="view-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/tickets/${ticket._id}`);
                        }}
                        aria-label={t('customerDetails.viewTicket')}
                      >
                        {t('customerDetails.viewTicket')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t('customerDetails.noTickets')}</p>
          )}
        </div>

        {/* Files Section */}
        <div className="section">
          <h3>
            <FaFileAlt /> {t('customerDetails.files')}
          </h3>
          {files && files.length > 0 ? (
            <ul className="files-list">
              {files.map((fileUrl, index) => (
                <li key={index}>
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <FaFileAlt className="file-icon" /> {t('customerDetails.file')}{' '}
                    {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('customerDetails.noFiles')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;