// src/components/CreateForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateForm.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaWpforms,
  FaPlus,
  FaTrash,
  FaTimes,
  FaExclamationTriangle,
  FaCheckCircle,
  FaSpinner,
} from 'react-icons/fa';

const CreateForm = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([{ label: '', placeholder: '' }]);
  const [forms, setForms] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deletingId, setDeletingId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchForms(token);
    }
  }, [user]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setError(t('createForm.fetchUserError'));
      setUser(null);
      setLoading(false);
    }
  };

  const fetchForms = async (token) => {
    try {
      const response = await axios.get('/api/forms/list', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setForms(response.data.forms);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch forms:', error);
      setError(t('createForm.fetchFormsError'));
      setLoading(false);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { label: '', placeholder: '' }]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = questions.map((question, i) =>
      i === index ? { ...question, [key]: value } : question
    );
    setQuestions(newQuestions);
  };

  const handleCreateForm = async (e) => {
    e.preventDefault();
    if (title.trim() === '') {
      setError(t('createForm.titleRequired'));
      return;
    }
    const invalidQuestion = questions.find(q => q.label.trim() === '');
    if (invalidQuestion) {
      setError(t('createForm.questionLabelRequired'));
      return;
    }
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/forms/create/',
        {
          form_schema: {
            title,
            fields: questions.map(q => ({
              type: 'text',
              label: q.label,
              placeholder: q.placeholder,
            })),
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTitle('');
      setQuestions([{ label: '', placeholder: '' }]);
      fetchForms(token);
      setShowPopup(false);
      setSuccessMessage(t('createForm.createSuccess'));
      setError('');
    } catch (error) {
      console.error('Failed to create form:', error);
      setError(t('createForm.createError'));
    }
  };

  const handleDeleteForm = async (formId) => {
    const confirmDelete = window.confirm(t('createForm.confirmDelete'));
    if (!confirmDelete) return;

    try {
      setDeletingId(formId);
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/forms/delete/${formId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchForms(token);
      setSuccessMessage(t('createForm.deleteSuccess'));
    } catch (error) {
      console.error('Failed to delete form:', error);
      setError(t('createForm.deleteError'));
    } finally {
      setDeletingId(null);
    }
  };

  const handleFormClick = (formId) => {
    navigate(`/form-result/${formId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setError('');
    setSuccessMessage('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setTitle('');
    setQuestions([{ label: '', placeholder: '' }]);
    setError('');
    setSuccessMessage('');
  };

  if (loading) {
    return (
      <div className="dashboard-container">
        <div className="loading-spinner">
          <FaSpinner className="spinner" /> {t('createForm.loading')}
        </div>
      </div>
    );
  }

  if (user === null) {
    return (
      <div className="dashboard-container">
        <div className="error-message">
          <FaExclamationTriangle /> {t('createForm.userFetchError')}
        </div>
      </div>
    );
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="dashboard-container">
        <div className="dashboard-card create-form-no-access">
          <FaExclamationTriangle className="error-icon" />
          <p>{t('createForm.noAccessMessage')}</p>
          <button
            onClick={() => navigate('/Payment')}
            className="upgrade-button"
            aria-label={t('createForm.upgradeNow')}
          >
            {t('createForm.upgradeNow')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-card create-form-container">
        <h2 className="section-title">
          <FaWpforms className="section-icon" />
          {t('createForm.title')}
        </h2>

        {/* Feedback Messages */}
        {error && (
          <div className="feedback-message error">
            <FaExclamationTriangle /> {error}
          </div>
        )}
        {successMessage && (
          <div className="feedback-message success">
            <FaCheckCircle /> {successMessage}
          </div>
        )}

        {/* Forms List */}
        <div className="create-form-list">
          {forms.length > 0 ? (
            forms.map((form) => (
              <div
                key={form.form_id}
                className="create-form-card"
                onClick={() => handleFormClick(form.form_id)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleFormClick(form.form_id);
                }}
              >
                <div className="create-form-title">{form.title}</div>
                <button
                  className="create-form-delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteForm(form.form_id);
                  }}
                  aria-label={t('createForm.deleteFormAriaLabel', { title: form.title })}
                  disabled={deletingId === form.form_id}
                >
                  {deletingId === form.form_id ? (
                    <FaSpinner className="spinner" />
                  ) : (
                    <FaTrash />
                  )}
                </button>
              </div>
            ))
          ) : (
            <p className="no-forms-message">{t('createForm.noForms')}</p>
          )}
        </div>

        {/* Create New Form Button */}
        <button className="create-form-new-button" onClick={togglePopup} aria-label={t('createForm.createNewForm')}>
          <FaPlus /> {t('createForm.createNewForm')}
        </button>

        {/* Create Form Popup */}
        {showPopup && (
          <div className="create-form-popup" role="dialog" aria-modal="true">
            <div className="create-form-popup-content">
              <button className="close-popup-button" onClick={closePopup} aria-label={t('createForm.closePopup')}>
                <FaTimes />
              </button>
              <h2>{t('createForm.createForm')}</h2>
              <form onSubmit={handleCreateForm} className="create-form-form">
                <div className="create-form-group">
                  <label htmlFor="title">{t('createForm.formTitle')} <span className="required">*</span></label>
                  <input
                    type="text"
                    id="title"
                    placeholder={t('createForm.enterFormTitle')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                {questions.map((question, index) => (
                  <div key={index} className="create-form-question-group">
                    <div className="create-form-group">
                      <label htmlFor={`label-${index}`}>{t('createForm.questionLabel')} <span className="required">*</span></label>
                      <input
                        type="text"
                        id={`label-${index}`}
                        placeholder={t('createForm.enterQuestion')}
                        value={question.label}
                        onChange={(e) => handleQuestionChange(index, 'label', e.target.value)}
                        required
                      />
                    </div>
                    <div className="create-form-group">
                      <label htmlFor={`placeholder-${index}`}>{t('createForm.placeholderLabel')}</label>
                      <input
                        type="text"
                        id={`placeholder-${index}`}
                        placeholder={t('createForm.enterPlaceholder')}
                        value={question.placeholder}
                        onChange={(e) => handleQuestionChange(index, 'placeholder', e.target.value)}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                      className="create-form-remove-question"
                      aria-label={t('createForm.removeQuestionAriaLabel')}
                    >
                      <FaTrash /> {t('createForm.removeQuestion')}
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  className="create-form-add-question"
                  aria-label={t('createForm.addQuestionAriaLabel')}
                >
                  <FaPlus /> {t('createForm.addQuestion')}
                </button>
                <div className="create-form-actions">
                  <button type="submit" className="create-form-submit" aria-label={t('createForm.submitForm')}>
                    {t('createForm.createForm')}
                  </button>
                  <button type="button" className="create-form-cancel" onClick={closePopup} aria-label={t('createForm.cancel')}>
                    <FaTimes /> {t('createForm.cancel')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateForm;
