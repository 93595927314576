import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDown, ChevronUp, CheckCircle, XCircle } from 'lucide-react';
import './UserParameter.css';

const EndpointTestModal = ({ isOpen, onClose }) => {
  const [endpointDetails, setEndpointDetails] = useState({ endpoint_url: '', endpoint_api_key: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [expandedEndpoints, setExpandedEndpoints] = useState({});
  const [orderReference, setOrderReference] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [shippingAddress, setShippingAddress] = useState({
    street1: '',
    street2: '',
    postcode: '',
    city: '',
    relay_id: ''
  });
  const [sqlQuery, setSqlQuery] = useState('');
  const [orderNote, setOrderNote] = useState('');
  const [aiLabel, setAiLabel] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchEndpointDetails();
    }
  }, [isOpen]);

  const handleShippingAddressChange = (e) => {
    setShippingAddress({
      ...shippingAddress,
      [e.target.name]: e.target.value
    });
  };

  const fetchEndpointDetails = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('access_token');
    try {
      const response = await axios.get('/api/users/user/endpoint', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setEndpointDetails({
          endpoint_url: response.data.endpoint_url || '',
          endpoint_api_key: response.data.endpoint_api_key || '',
        });
      }
    } catch (error) {
      console.error('Failed to fetch endpoint details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateEndpoint = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
      await axios.put('/api/users/user/endpoint', endpointDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Endpoint details updated successfully.');
    } catch (error) {
      console.error('Failed to update endpoint details:', error);
      alert('Error updating endpoint details.');
    }
  };

  const handleTestAllEndpoints = async () => {
    setIsTesting(true);
    const token = sessionStorage.getItem('access_token');
    try {
      const filteredShippingAddress = Object.fromEntries(
        Object.entries(shippingAddress).filter(([_, v]) => v !== '')
      );
      const [allEndpointsResponse, 
        orderDetailsResponse, 
        orderDetailsEdnResponse, 
        updateShippingAddressResponse, 
        executeSecureQueryResponse,
        addOrderNoteResponse,
        addOrderAILabelResponse
      ] = await Promise.all([
        axios.post('/api/test_endpoints/test-all-endpoints', {}, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/test_endpoints/test-order-details', {
          params: { order_reference: orderReference },
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/test_endpoints/test-order-details-edn', {
          params: { 
            order_reference: orderReference,
            customer_email: customerEmail
          },
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.put('/api/test_endpoints/test-update-shipping-address', 
          filteredShippingAddress,
          {
            params: { order_reference: orderReference },
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
        axios.post('/api/test_endpoints/test-execute-secure-query', 
          { query: sqlQuery },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
        axios.post('/api/test_endpoints/test-add-order-note', 
          { 
            order_reference: orderReference,
            note: orderNote
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        ),
        axios.post('/api/test_endpoints/test-add-order-ai-label', 
          { 
            order_reference: orderReference,
            ai_label: aiLabel
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      ]);
      
      setTestResults({
        ...allEndpointsResponse.data,
        order_details: orderDetailsResponse.data,
        order_details_edn: orderDetailsEdnResponse.data,
        update_shipping_address: updateShippingAddressResponse.data,
        execute_secure_query: executeSecureQueryResponse.data,
        add_order_note: addOrderNoteResponse.data,
        add_order_ai_label: addOrderAILabelResponse.data
      });
    } catch (error) {
      console.error('Error testing endpoints:', error);
      setTestResults(null);
    } finally {
      setIsTesting(false);
    }
  };


  const toggleEndpointExpansion = (endpointName) => {
    setExpandedEndpoints(prev => ({
      ...prev,
      [endpointName]: !prev[endpointName]
    }));
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Modify & Test Endpoints</h2>
          <button className="close-button-circle" onClick={onClose}></button>

          <div className="form-group">
            <label htmlFor="endpoint-url">Endpoint URL</label>
            <input
              type="text"
              id="endpoint-url"
              value={endpointDetails.endpoint_url}
              onChange={(e) => setEndpointDetails({ ...endpointDetails, endpoint_url: e.target.value })}
              placeholder="Enter the endpoint URL"
            />
          </div>

          <div className="form-group">
            <label htmlFor="api-key">API Key</label>
            <input
              type="text"
              id="api-key"
              value={endpointDetails.endpoint_api_key}
              onChange={(e) => setEndpointDetails({ ...endpointDetails, endpoint_api_key: e.target.value })}
              placeholder="Enter the API Key"
            />
          </div>

          <div className="form-group">
            <label htmlFor="order-reference">Order Reference</label>
            <input
              type="text"
              id="order-reference"
              value={orderReference}
              onChange={(e) => setOrderReference(e.target.value)}
              placeholder="Enter an order reference"
            />
          </div>

          <div className="form-group">
            <label htmlFor="customer-email">Customer Email</label>
            <input
              type="email"
              id="customer-email"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              placeholder="Enter customer email"
            />
          </div>

          <h3>Update Shipping Address (All fields optional)</h3>
          <div className="form-group">
            <label htmlFor="street1">Street 1 (optional)</label>
            <input
              type="text"
              id="street1"
              name="street1"
              value={shippingAddress.street1}
              onChange={handleShippingAddressChange}
              placeholder="Enter street 1 (optional)"
            />
          </div>
          <div className="form-group">
            <label htmlFor="street2">Street 2 (optional)</label>
            <input
              type="text"
              id="street2"
              name="street2"
              value={shippingAddress.street2}
              onChange={handleShippingAddressChange}
              placeholder="Enter street 2 (optional)"
            />
          </div>
          <div className="form-group">
            <label htmlFor="postcode">Postcode (optional)</label>
            <input
              type="text"
              id="postcode"
              name="postcode"
              value={shippingAddress.postcode}
              onChange={handleShippingAddressChange}
              placeholder="Enter postcode (optional)"
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City (optional)</label>
            <input
              type="text"
              id="city"
              name="city"
              value={shippingAddress.city}
              onChange={handleShippingAddressChange}
              placeholder="Enter city (optional)"
            />
          </div>
          <div className="form-group">
            <label htmlFor="relay_id">Relay ID (optional)</label>
            <input
              type="text"
              id="relay_id"
              name="relay_id"
              value={shippingAddress.relay_id}
              onChange={handleShippingAddressChange}
              placeholder="Enter relay ID (optional)"
            />
          </div>
          <div className="form-group">
            <label htmlFor="sql-query">SQL Query</label>
            <textarea
              id="sql-query"
              value={sqlQuery}
              onChange={(e) => setSqlQuery(e.target.value)}
              placeholder="Enter SQL query to execute"
              rows={4}
            />
          </div>

          <div className="form-group">
            <label htmlFor="order-note">Order Note</label>
            <textarea
              id="order-note"
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
              placeholder="Enter a note for the order"
              rows={4}
            />
          </div>

          <div className="form-group">
            <label htmlFor="ai-label">AI Label</label>
            <input
              type="text"
              id="ai-label"
              value={aiLabel}
              onChange={(e) => setAiLabel(e.target.value)}
              placeholder="Enter an AI label for the order"
            />
          </div>

          <button className="primary-button" onClick={handleUpdateEndpoint}>
            Save Endpoint Details
          </button>

          <button 
            className="primary-button" 
            onClick={handleTestAllEndpoints} 
            disabled={isTesting || !orderReference || !customerEmail || !sqlQuery || !orderNote || !aiLabel}
          >
            {isTesting ? 'Testing...' : 'Test All Endpoints'}
          </button>

          {testResults && (
            <div className="test-results">
              <h4>Test Results:</h4>
              {Object.entries(testResults).map(([endpointName, result]) => (
                <div key={endpointName} className="endpoint-result">
                  <div 
                    className="endpoint-header" 
                    onClick={() => toggleEndpointExpansion(endpointName)}
                  >
                    {result.status === 'success' 
                      ? <CheckCircle className="icon success" /> 
                      : <XCircle className="icon error" />}
                    <span>{endpointName}</span>
                    {expandedEndpoints[endpointName] ? <ChevronUp /> : <ChevronDown />}
                  </div>
                  {expandedEndpoints[endpointName] && (
                    <pre className="endpoint-details">
                      {JSON.stringify(result, null, 2)}
                    </pre>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default EndpointTestModal;