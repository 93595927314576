import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaTruck } from 'react-icons/fa';
import './CarriersConversation.css';

const CarriersConversation = ({ conversation }) => {
    const { t } = useTranslation();

    if (!conversation || !conversation.messages) {
        return null;
    }

    return (
        <div className="carriers-conversation">
            <div className="carriers-header">
                <FaTruck />
                <h2>{t('carriersConversation.title')}</h2>
            </div>
            <div className="carriers-messages">
                {conversation.messages.map((message, index) => (
                    <div key={index} className="carrier-message">
                        <div className="carrier-message-header">
                            <span className="carrier-name">{message.carrier}</span>
                            <span className="message-timestamp">{new Date(message.timestamp).toLocaleString()}</span>
                        </div>
                        <div className="carrier-message-content">
                            {message.content}
                        </div>
                        {message.status && (
                            <div className="carrier-message-status">
                                {t(`carriersConversation.status.${message.status}`)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CarriersConversation;