import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppIntegration.css';

const WhatsAppIntegration = ({ onSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const client_id = '1595755791259409';
  const config_id = '1559434427953044';
  const redirect_uri = '/api/social/whatsapp/callback';

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    const params = new URLSearchParams(location.search);
    if (params.get('status') === 'success') {
      const phoneNumber = params.get('phone_number');
      onSuccess(phoneNumber);
    }
  }, [navigate, location, onSuccess]);

  const launchWhatsAppSignup = () => {
    const token = sessionStorage.getItem('access_token');
    const oauth2Endpoint = 'https://www.facebook.com/v18.0/dialog/oauth';

    const params = {
      client_id: client_id,
      redirect_uri: redirect_uri,
      response_type: 'code',
      config_id: config_id,
      state: token
    };

    const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`;
    window.location.replace(oauthURL);
  };

  return (
    <div className="whatsapp-integration">
      <h2 className="whatsapp-title">
        <FaWhatsapp className="whatsapp-icon" />
        {t('whatsAppIntegration.title')}
      </h2>
      <div className="whatsapp-steps">
        <p>{t('whatsAppIntegration.step1')}</p>
        <p>{t('whatsAppIntegration.step2')}</p>
        <p className="whatsapp-ready">{t('whatsAppIntegration.ready')}</p>
      </div>
      <button onClick={launchWhatsAppSignup} className="whatsapp-button">
        <FaWhatsapp />
        {t('whatsAppIntegration.start')}
      </button>
    </div>
  );
};

export default WhatsAppIntegration;