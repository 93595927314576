import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserParameter.css';
import { useNavigate, Link } from 'react-router-dom';
import WhatsAppIntegration from './WhatsAppIntegration';
import ChatbotSettings from './ChatbotSettings';
import { useTranslation } from 'react-i18next';
import EndpointTestModal from './EndpointTestModal';
import { FaUser, FaEnvelope, FaLock, FaTrash, FaCog, FaInstagram, FaGoogle, FaWhatsapp, FaRobot, FaWarehouse, FaTruck } from 'react-icons/fa';

const UserParameter = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [confirmUsername, setConfirmUsername] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEDNForm, setShowEDNForm] = useState(false);
  const [endpointDetails, setEndpointDetails] = useState({ endpoint_url: '', endpoint_api_key: '' });
  const [isEndpointLoading, setIsEndpointLoading] = useState(false);
  const [ednCredentials, setEdnCredentials] = useState({ edn_api_account: '', edn_api_key: '' });
  const [showWhatsAppIntegration, setShowWhatsAppIntegration] = useState(false); // State to show WhatsApp integration
  const [showChatbotSettings, setShowChatbotSettings] = useState(false); // State to show Chatbot Settings popup
  const [instagramAccounts, setInstagramAccounts] = useState([]); // Add this line
  const [isWmsConnecting, setIsWmsConnecting] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [showCarrierForm, setShowCarrierForm] = useState(false);
  const [newCarrier, setNewCarrier] = useState({
    name: '',
    email: '',
    contact_rules: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/'); // Redirect if no token found
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/'); // Redirect if user data is explicitly null and not loading
    }
  }, [user, navigate]);

  useEffect(() => {
    fetchCarriers();
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setUser({
          username: response.data.username || '',
          firstname: response.data.firstname || '',
          lastname: response.data.lastname || '',
          email: response.data.email || '',
          subscription_plan: response.data.subscription_plan || '',
          user_meta_id: response.data.user_meta_id || '',
          user_insta_id: response.data.user_insta_id || '',
          gmail_email_address: response.data.gmail_email || '',
          automation: response.data.automation || '',
          shop_name: response.data.shop_name || '',
          instagram_username: response.data.insta_username || '',
          whatsapp_phone_number: response.data.whatsapp_phone_number || '',
          edn_api_account: response.data.edn_api_account || '',
        });
      } else {
        setUser(null); // Ensure user is set to null if fetch fails
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null); // Ensure user is set to null on error
    }
  };

  const fetchCarriers = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/carriers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCarriers(response.data);
    } catch (error) {
      console.error('Failed to fetch carriers:', error);
      setPopupMessage('Failed to fetch carriers list.');
    }
  };

  const checkConnectionStatuses = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const instaStatus = urlParams.get('status');
    const googleStatus = urlParams.get('google_status');
    const shopifyStatus = urlParams.get('shopify_status');

    if (instaStatus === 'success') {
      setPopupMessage('Account connected successfully!');
    } else if (instaStatus === 'failure') {
      setPopupMessage('Failed to connect Instagram account.');
    }

    if (googleStatus === 'success') {
      setPopupMessage('Gmail connected successfully!');
    } else if (googleStatus === 'failure') {
      setPopupMessage('Failed to connect Google account.');
    }

    if (shopifyStatus === 'success') {
      setPopupMessage('Shopify store connected successfully!');
    } else if (shopifyStatus === 'failure') {
      setPopupMessage('Failed to connect Shopify account.');
    }
  };


  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert('New passwords do not match.');
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('/api/users/update-user', {
        currentPassword,
        password: newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      alert('Password updated successfully.');
      setIsPasswordPopupVisible(false);
    } catch (error) {
      console.error('Failed to update password:', error);
      alert('Error updating password.');
    }
  };
  const fetchEndpointDetails = async () => {
    setIsEndpointLoading(true);
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }
    
    try {
      const response = await axios.get('/api/users/user/endpoint', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setEndpointDetails({
          endpoint_url: response.data.endpoint_url || '',
          endpoint_api_key: response.data.endpoint_api_key || '',
        });
      }
    } catch (error) {
      console.error('Failed to fetch endpoint details:', error);
      setEndpointDetails({ endpoint_url: '', endpoint_api_key: '' });
    } finally {
      setIsEndpointLoading(false);
    }
  };

  const handleUpdateEndpoint = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
      await axios.put('/api/users/user/endpoint', endpointDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Endpoint details updated successfully.');
      fetchEndpointDetails();  // Refresh the data after update
    } catch (error) {
      console.error('Failed to update endpoint details:', error);
      alert('Error updating endpoint details.');
    }
  };
  
  
  useEffect(() => {
    fetchEndpointDetails();
  }, []);
  

  const handleProfileUpdate = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put('/api/users/update-user', {
        username: user.username,
        whatsapp_phone_number: user.whatsapp_phone_number
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Profile updated successfully.');
      fetchUserData(token);
    } catch (error) {
      console.error('Failed to update profile:', error);
      alert('Error updating profile.');
    }
  };

  const handleConnectEDN = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/logistics/edn/connect', ednCredentials, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, edn_api_account: ednCredentials.edn_api_account });
      setPopupMessage('EDN connected successfully!');
      setShowEDNForm(false);
    } catch (error) {
      console.error('Failed to connect EDN account:', error);
      setPopupMessage('Failed to connect EDN account.');
    }
  };
  
  const handleDisconnectEDN = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/logistics/edn/disconnect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, edn_api_account: '' });
      setPopupMessage('EDN disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect EDN account:', error);
      setPopupMessage('Failed to disconnect EDN account.');
    }
  };

  const handleConnectInstagram = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/social/instagram/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Failed to connect Instagram account:', error);
    }
  };

  useEffect(() => {
    checkConnectionStatuses();

    // Check if the URL contains Instagram callback parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (code && state) {
      handleInstagramCallback(code, state);
    }
  }, []);

  const handleInstagramCallback = async (code, state) => {
    const data = await fetchInstagramAccounts(code, state);
    if (data) {
      setInstagramAccounts(data.accounts);
    }
  };

  const fetchInstagramAccounts = async (code, state) => {
    try {
      const response = await axios.get(`/api/social/instagram/callback?code=${code}&state=${state}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch Instagram accounts:', error);
      return null;
    }
  };

  const handleAccountSelect = async (accountId, accountName) => {
    try {
      const token = sessionStorage.getItem('access_token');
      if (!token) {
        alert('You are not logged in. Please log in again.');
        navigate('/login');
        return;
      }

      const payload = {
        account_id: accountId,
        name: accountName
      };

      console.log('Sending payload:', payload);

      await axios.post('/api/social/instagram/select_account', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      navigate('/parameters?status=success');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Session expired. Please log in again.');
        navigate('/login');
      } else {
        console.error('Failed to select Instagram account:', error);
        alert('Failed to connect Instagram account.');
      }
    }
  };

  const handleConnectGoogle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/social/google/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Failed to connect Google account:', error);
      setPopupMessage('Failed to connect Google account.');
    }
  };

  const togglePasswordPopup = () => {
    setIsPasswordPopupVisible(!isPasswordPopupVisible);
  };

  const handleDisconnectInstagram = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/social/instagram/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, user_insta_id: '', instagram_username: '' });
      setPopupMessage('Instagram account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Instagram account:', error);
      setPopupMessage('Failed to disconnect Instagram account.');
    }
  };

  const handleDisconnectGoogle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/social/google/unsubscribe', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, gmail_email_address: '' });
      setPopupMessage('Google account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect Google account:', error);
      setPopupMessage('Failed to disconnect Google account.');
    }
  };
  const handleDisconnectWhatsApp = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/social/disconnect/whatsappbusiness', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, whatsapp_phone_number: '' });
      setPopupMessage('WhatsApp account disconnected successfully!');
    } catch (error) {
      console.error('Failed to disconnect WhatsApp account:', error);
      setPopupMessage('Failed to disconnect WhatsApp account.');
    }
  };

  const handleDeleteUser = async () => {
    if (confirmUsername !== user.username) {
      alert('Username does not match.');
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete('/api/users/user/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.removeItem('access_token');
      navigate('/');
    } catch (error) {
      console.error('Failed to delete user account:', error);
      alert('Error deleting user account.');
    }
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupVisible(!isDeletePopupVisible);
  };
  const handleCloseWhatsAppIntegration = () => {
    setShowWhatsAppIntegration(false);
  };

  const handleConnectWhatsApp = () => {
    setShowWhatsAppIntegration(true); // Show the WhatsApp integration component
  };

  const handleOpenChatbotSettings = () => {
    setShowChatbotSettings(true); // Show the Chatbot Settings component
  };

  const handleCloseChatbotSettings = () => {
    setShowChatbotSettings(false); // Hide the Chatbot Settings component
  };

  const handleConnectWMS = async () => {
    try {
      setIsWmsConnecting(true);
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/users/associate-wms', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPopupMessage('Successfully connected to Unanimity WMS!');
      // Refresh user data to get updated WMS status
      fetchUserData(token);
    } catch (error) {
      console.error('Failed to connect to WMS:', error);
      if (error.response?.status === 403) {
        setPopupMessage('Only admin and logistics users can connect to WMS.');
      } else {
        setPopupMessage('Failed to connect to Unanimity WMS.');
      }
    } finally {
      setIsWmsConnecting(false);
    }
  };

  const handleCreateCarrier = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/logistics/carriers', newCarrier, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPopupMessage('Carrier created successfully!');
      setShowCarrierForm(false);
      setNewCarrier({ name: '', email: '', contact_rules: '' });
      fetchCarriers(); // Refresh the carriers list
    } catch (error) {
      console.error('Failed to create carrier:', error);
      if (error.response?.status === 403) {
        setPopupMessage('Only logistics users can create carriers.');
      } else if (error.response?.status === 400) {
        setPopupMessage('Carrier with this name or email already exists.');
      } else {
        setPopupMessage('Failed to create carrier.');
      }
    }
  };

  const renderCarriersSection = () => (
    <div className="carriers-section">
      <h2 className="section-title"><FaTruck /> Carriers Management</h2>
      <div className="carriers-list">
        {carriers.map(carrier => (
          <div key={carrier.id} className="carrier-item">
            <h3>{carrier.name}</h3>
            <p><strong>Email:</strong> {carrier.email}</p>
            <p><strong>Contact Rules:</strong> {carrier.contact_rules}</p>
          </div>
        ))}
      </div>
      {user?.role === 'logistics' && (
        <button className="add-carrier-button" onClick={() => setShowCarrierForm(true)}>
          Add New Carrier
        </button>
      )}

      {showCarrierForm && (
        <Modal onClose={() => setShowCarrierForm(false)} title="Add New Carrier">
          <div className="carrier-form">
            <div className="form-group">
              <label htmlFor="carrier-name">Carrier Name</label>
              <input
                type="text"
                id="carrier-name"
                value={newCarrier.name}
                onChange={(e) => setNewCarrier({ ...newCarrier, name: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="carrier-email">Email</label>
              <input
                type="email"
                id="carrier-email"
                value={newCarrier.email}
                onChange={(e) => setNewCarrier({ ...newCarrier, email: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="carrier-rules">Contact Rules</label>
              <textarea
                id="carrier-rules"
                value={newCarrier.contact_rules}
                onChange={(e) => setNewCarrier({ ...newCarrier, contact_rules: e.target.value })}
              />
            </div>
            <button className="create-button" onClick={handleCreateCarrier}>
              Create Carrier
            </button>
          </div>
        </Modal>
      )}
    </div>
  );

  return (
    <div className="user-parameter-container">
      {user === undefined ? (
        <div className="loading">{t('userParameter.loading')}</div>
      ) : user ? (
        <>
          <div className="user-info-section">
            <h2 className="section-title"><FaUser /> {t('userParameter.userInfo')}</h2>
            <div className="user-info-grid">
              <div className="info-item">
                <FaUser className="info-icon" />
                <div>
                  <strong>{t('userParameter.username')}</strong>
                  <p>{user.username}</p>
                </div>
              </div>
              <div className="info-item">
                <FaEnvelope className="info-icon" />
                <div>
                  <strong>{t('userParameter.email')}</strong>
                  <p>{user.email}</p>
                </div>
              </div>
              {/* Add more user info items here */}
            </div>
            <button className="change-password-button" onClick={togglePasswordPopup}>
              <FaLock /> {t('userParameter.changePassword')}
            </button>
          </div>

          <div className="integrations-section">
            <h2 className="section-title"><FaCog /> {t('userParameter.integrations')}</h2>
            <div className="integration-buttons">
              <IntegrationButton
                icon={<FaInstagram />}
                label="Instagram"
                connected={!!user.user_insta_id}
                onConnect={handleConnectInstagram}
                onDisconnect={handleDisconnectInstagram}
              />
              <IntegrationButton
                icon={<FaGoogle />}
                label="Google"
                connected={!!user.gmail_email_address}
                onConnect={handleConnectGoogle}
                onDisconnect={handleDisconnectGoogle}
              />
              <IntegrationButton
                icon={<FaWhatsapp />}
                label="WhatsApp"
                connected={!!user.whatsapp_phone_number}
                onConnect={handleConnectWhatsApp}
                onDisconnect={handleDisconnectWhatsApp}
              />
              <IntegrationButton
                icon={<FaRobot />}
                label="Chatbot"
                connected={true}
                onConnect={() => navigate('/chatbot-settings')}
                onDisconnect={() => navigate('/chatbot-settings')}
              />
              <IntegrationButton
                icon={<FaWarehouse />}
                label="Unanimity WMS"
                connected={!!user.unanimity_wms_id}
                onConnect={handleConnectWMS}
                onDisconnect={() => {}} // WMS connection can't be disconnected
                loading={isWmsConnecting}
              />
            </div>
          </div>

          <div className="subscription-section">
            <h2 className="section-title">{t('userParameter.subscriptionPlan')}</h2>
            <div className="subscription-info">
              <p>{user.subscription_plan}</p>
              <Link to="/billing" className="manage-subscription-button">
                {t('userParameter.manageSubscription')}
              </Link>
            </div>
          </div>
          <div className="edn-integration-section">
            <h2 className="section-title">EDN Integration</h2>
            <p>This feature is for users who need to integrate with Envoi du Net (EDN) for shipping services.</p>
            {user.edn_api_account ? (
              <div>
                <p>EDN is currently connected with account: {user.edn_api_account}</p>
                <button className="disconnect-button" onClick={handleDisconnectEDN}>
                  Disconnect EDN
                </button>
              </div>
            ) : (
              <button className="connect-button" onClick={() => setShowEDNForm(true)}>
                Connect EDN
              </button>
            )}
          </div>
          <div className="endpoint-section">
            <h2 className="section-title">Endpoint Integration</h2>
            <button className="update-endpoint-button" onClick={() => setIsModalOpen(true)}>
              Modify & Test Endpoint
            </button>

            {/* Modal for modifying and testing endpoint */}
            <EndpointTestModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              endpointDetails={endpointDetails}
              setEndpointDetails={setEndpointDetails}
            />
          </div>
          {renderCarriersSection()}
          <div className="danger-zone">
            <h2 className="section-title">{t('userParameter.dangerZone')}</h2>
            <button className="delete-account-button" onClick={toggleDeletePopup}>
              <FaTrash /> {t('userParameter.deleteAccount')}
            </button>
          </div>
        </>
      ) : (
        <div className="error">{t('userParameter.loadError')}</div>
      )}

      {/* Modals */}
      {isPasswordPopupVisible && (
        <Modal onClose={togglePasswordPopup} title={t('userParameter.changePassword')}>
          <form onSubmit={handlePasswordSubmit} className="password-form">
            <div className="form-group">
              <label htmlFor="current-password">{t('userParameter.currentPassword')}</label>
              <input
                type="password"
                id="current-password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="new-password">{t('userParameter.newPassword')}</label>
              <input
                type="password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-new-password">{t('userParameter.confirmNewPassword')}</label>
              <input
                type="password"
                id="confirm-new-password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="primary-button">
                {t('userParameter.updatePassword')}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {isDeletePopupVisible && (
        <Modal onClose={toggleDeletePopup} title={t('userParameter.deleteAccount')}>
          <form onSubmit={handleDeleteUser} className="delete-account-form">
            <p className="warning-text">{t('userParameter.deleteAccountWarning')}</p>
            <div className="form-group">
              <label htmlFor="confirm-username">{t('userParameter.confirmUsername')}</label>
              <input
                type="text"
                id="confirm-username"
                value={confirmUsername}
                onChange={(e) => setConfirmUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="danger-button">
                {t('userParameter.confirmDelete')}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showWhatsAppIntegration && (
        <Modal onClose={handleCloseWhatsAppIntegration} title={t('userParameter.whatsAppIntegration')}>
          <WhatsAppIntegration />
        </Modal>
      )}

      {showEDNForm && (
        <Modal onClose={() => setShowEDNForm(false)} title="Connect EDN">
          <div className="edn-form">
            <div className="form-group">
              <label htmlFor="edn-api-account">EDN API Account</label>
              <input
                type="text"
                id="edn-api-account"
                value={ednCredentials.edn_api_account}
                onChange={(e) => setEdnCredentials({ ...ednCredentials, edn_api_account: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="edn-api-key">EDN API Key</label>
              <input
                type="password"
                id="edn-api-key"
                value={ednCredentials.edn_api_key}
                onChange={(e) => setEdnCredentials({ ...ednCredentials, edn_api_key: e.target.value })}
              />
            </div>
            <button className="connect-button" onClick={handleConnectEDN}>
              Connect EDN
            </button>
          </div>
        </Modal>
      )}

      {showChatbotSettings && (
        <Modal onClose={handleCloseChatbotSettings} title={t('userParameter.chatbotSettings')}>
          <ChatbotSettings />
        </Modal>
      )}
    </div>
  );
};

const IntegrationButton = ({ icon, label, connected, onConnect, onDisconnect, loading }) => {
  const buttonClass = connected ? 'integration-button connected' : 'integration-button';
  const handleClick = connected ? onDisconnect : onConnect;

  return (
    <button className={buttonClass} onClick={handleClick} disabled={loading}>
      {icon}
      <span>{label}</span>
      <span className="connection-status">{connected ? 'Connected' : 'Not Connected'}</span>
    </button>
  );
};

const Modal = ({ children, onClose, title }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content large-modal"> {/* Add 'large-modal' class */}
        <h2>{title}</h2>
        <button className="close-button-circle" onClick={onClose}></button>
        {children}
      </div>
    </div>
  );
};

export default UserParameter;