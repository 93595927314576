import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './InstagramAccountSelection.css'; // Import the CSS file

const InstagramAccountSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accounts, setAccounts] = useState([]);

  // Parse accounts and state from query parameters
  const queryParams = new URLSearchParams(location.search);
  const accountsJson = queryParams.get('accounts');
  const state = queryParams.get('state');

  useEffect(() => {
    if (accountsJson) {
      setAccounts(JSON.parse(accountsJson));
    }
  }, [accountsJson]);

  const handleAccountSelect = async (accountId, accountName, access_token, instagram_business_account) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/social/instagram/select_account', {
        account_id: accountId,
        name: accountName,
        access_token: access_token,
        instagram_business_account: instagram_business_account
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate('/parameters?status=success');
    } catch (error) {
      console.error('Failed to select Instagram account:', error);
      alert('Failed to connect Instagram account.');
    }
  };

  return (
    <div className="instagram-account-selection">
      <h2>Select Instagram Account</h2>
      <ul>
        {accounts.map((account) => (
          <li key={account.id}>
            <button onClick={() => handleAccountSelect(account.id, account.name, account.access_token, account.instagram_business_account.id)}>
              {account.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InstagramAccountSelection;
