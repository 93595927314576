import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatbotSettings.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChatbotPreview from './ChatbotPreview'; // Import the ChatbotPreview component

const ChatbotSettings = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState({
    primary_color: '#6200ea', // Changed default primary color for better aesthetics
    secondary_color: '#03dac6', // Changed default secondary color
    tertiary_color: '#bb86fc',
    company_name: 'Your Company',
    agent_name: 'ChatBot',
    greetings_message: 'Hello! How can I assist you today?',
  });
  const [embedCode, setEmbedCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }
    fetchChatbotSettings(token);
    generateEmbedCode(token);
  }, [navigate]);

  const fetchChatbotSettings = async (token) => {
    try {
      const response = await axios.get('/api/chatbot/settings', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSettings(response.data);
    } catch (error) {
      console.error('Failed to fetch chatbot settings:', error);
    }
  };

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
      console.log('Settings to be saved:', settings);
      await axios.post('/api/chatbot/settings', settings, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(t('chatbotSettings.settingsSaved'));
    } catch (error) {
      console.error('Failed to save settings:', error);
      alert(t('chatbotSettings.saveError')); // Add a translation key for save error
    }
  };

  const generateEmbedCode = async (token) => {
    try {
      const response = await axios.get('/api/chatbot/embed', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEmbedCode(response.data.embed_code);
    } catch (error) {
      console.error('Failed to generate embed code:', error);
    }
  };

  return (
    <div className="chatbot-settings-container">
      <h2 className="settings-title">{t('chatbotSettings.title')}</h2>

      {/* Flex Layout */}
      <div className="chatbot-settings-flex">
        {/* Settings Form */}
        <div className="chatbot-settings-form">
          <form>
            {/* Company Name */}
            <div className="form-group">
              <label htmlFor="company_name">{t('chatbotSettings.companyName')}</label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                value={settings.company_name}
                onChange={handleChange}
                placeholder="Enter your company name"
              />
            </div>

            {/* Agent Name */}
            <div className="form-group">
              <label htmlFor="agent_name">{t('chatbotSettings.aiAgentName')}</label>
              <input
                type="text"
                id="agent_name"
                name="agent_name"
                value={settings.agent_name}
                onChange={handleChange}
                placeholder="Enter chatbot name"
              />
            </div>

            {/* Greetings Message */}
            <div className="form-group">
              <label htmlFor="greetings_message">{t('chatbotSettings.greetingMessage')}</label>
              <textarea
                id="greetings_message"
                name="greetings_message"
                value={settings.greetings_message}
                onChange={handleChange}
                placeholder="Enter greeting message"
                rows="3"
              ></textarea>
            </div>

            {/* Primary Color */}
            <div className="form-group color-group">
              <label htmlFor="primary_color">{t('chatbotSettings.primaryColor')}</label>
              <input
                type="color"
                id="primary_color"
                name="primary_color"
                value={settings.primary_color}
                onChange={handleChange}
              />
            </div>

            {/* Secondary Color */}
            <div className="form-group color-group">
              <label htmlFor="secondary_color">{t('chatbotSettings.secondaryColor')}</label>
              <input
                type="color"
                id="secondary_color"
                name="secondary_color"
                value={settings.secondary_color}
                onChange={handleChange}
              />
            </div>

            {/* Tertiary Color */}
            <div className="form-group color-group">
              <label htmlFor="tertiary_color">{t('chatbotSettings.tertiaryColor')}</label>
              <input
                type="color"
                id="tertiary_color"
                name="tertiary_color"
                value={settings.tertiary_color}
                onChange={handleChange}
              />
            </div>

            {/* Save Button */}
            <button type="button" onClick={handleSave} className="save-button">
              {t('chatbotSettings.save')}
            </button>
          </form>

          {/* Embed Code Section */}
          <div className="embed-code-section">
            <h3>{t('chatbotSettings.embedCodeTitle')}</h3>
            <textarea
              readOnly
              value={embedCode}
              className="embed-code-textarea"
            />
            <button
              className="copy-embed-button"
              onClick={() => {
                navigator.clipboard.writeText(embedCode);
                alert(t('chatbotSettings.copySuccess'));
              }}
            >
              {t('chatbotSettings.copyEmbedCode')}
            </button>
          </div>
        </div>

        {/* Chatbot Preview */}
        <div className="chatbot-preview-section">
          <h3 className="preview-title">{t('chatbotSettings.previewTitle')}</h3>
          <ChatbotPreview
            primary_color={settings.primary_color}
            secondary_color={settings.secondary_color}
            tertiary_color={settings.tertiary_color}
            company_name={settings.company_name}
            agent_name={settings.agent_name}
            greetings_message={settings.greetings_message}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatbotSettings;
