import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ConversationDetails.css';
import FloatingChatbotLogistic from './FloatingChatbotLogistic';

const ConversationDetails = () => {
  const { t } = useTranslation();
  const { conversationId } = useParams();
  const [conversation, setConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get(`/api/logistics/logistician-conversations/${conversationId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setConversation(response.data);
        setLoading(false);

        // Decode the token to get the user role
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setUserRole(decodedToken.role);
      } catch (err) {
        console.error('Error fetching conversation:', err);
        setError(t('conversationDetails.fetchError'));
        setLoading(false);
      }
    };

    fetchConversation();
  }, [conversationId, t]);

  const handleSendMessage = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(`/api/logistics/logistician-conversations/${conversationId}/messages`, 
        { message: newMessage },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Refresh the conversation after sending a message
      const response = await axios.get(`/api/logistics/logistician-conversations/${conversationId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setConversation(response.data);
      setNewMessage('');
    } catch (err) {
      console.error('Error sending message:', err);
      setError(t('conversationDetails.sendError'));
    }
  };

  if (loading) return <div>{t('common.loading')}</div>;
  if (error) return <div>{error}</div>;
  if (!conversation) return <div>{t('conversationDetails.notFound')}</div>;

  return (
    <div className="conversation-window-container">
      <div className="conversation-window">
        <div className="conversation-header">
          <h2>{t('conversationDetails.title')}</h2>
        </div>
        <div className="message-list">
          {conversation.messages.map((message, index) => (
            <div key={index} className={`message ${message.sender_role === userRole ? 'logistics' : 'admin'}`}>
              <p className="message-content">{message.content}</p>
              <div className="message-meta">
                <span>{message.sender_role}</span>
                <span>{new Date(message.timestamp).toLocaleString()}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="response-input">
          <textarea
            className="conversation-window-textarea"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={t('conversationDetails.inputPlaceholder')}
          />
          <div className="response-actions">
            <button className="conversation-window-button send-button" onClick={handleSendMessage}>
              {t('conversationDetails.sendButton')}
            </button>
          </div>
        </div>
        <FloatingChatbotLogistic />
      </div>
    </div>
  );
};

export default ConversationDetails;