import React from 'react';
import { Navigate, useParams, useLocation, Outlet } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const token = sessionStorage.getItem('access_token');
  const { conversationId } = useParams();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" replace />;
  }

  let decodedToken;
  try {
    decodedToken = jwtDecode(token);
  } catch (error) {
    console.error('Invalid token:', error);
    sessionStorage.removeItem('access_token');
    return <Navigate to="/" replace />;
  }

  const userRole = decodedToken.role;

  // If the user is a logistics user and not on an allowed page, redirect to returns
  if (userRole === 'logistics' && 
      !location.pathname.startsWith('/returns') && 
      !location.pathname.startsWith('/parameters') && 
      !location.pathname.startsWith('/logistics-ai-assistants') && 
      !location.pathname.startsWith('/conversation') &&
      !location.pathname.startsWith('/order-logistics')&&
      !location.pathname.startsWith('/dashboard-logs')&&
      !location.pathname.startsWith('/form-result')&&
      !location.pathname.startsWith('/tickets/archive')&&
      !/^\/tickets\/[^/]+\/messages/.test(location.pathname) &&
      !location.pathname.startsWith('/logistics-statistics')) {
    return <Navigate to="/returns" replace />;
  }

  // For other roles, check if they have access to the current route
  if (roles && !roles.includes(userRole)) {
    // Redirect admin and employee to dashboard, logistics to returns
    const redirectPath = userRole === 'logistics' ? '/returns' : '/dashboard';
    return <Navigate to={redirectPath} replace />;
  }

  return <Component {...rest} conversationId={conversationId} isAuthenticated={true} userRole={userRole} />;
};

export default PrivateRoute;