import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ResetPassword.css';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage("New passwords do not match");
      return;
    }
    
    try {
      const response = await axios.post(`/api/auth/reset-password/${token}`, {
        new_password: newPassword,
      });
      setMessage(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage(`Reset failed: ${error.response.data.detail}`);
      } else {
        setMessage('Reset failed: An unexpected error occurred');
      }
    }
  };

  return (
    <div className="outer-container">
      <div className="reset-password-container">
        <div className="reset-password-header">
          <img src="/menu_logo.png" alt="Logo" className="logo" />
        </div>
        <h2>Reset Password</h2>
        {message && <p>{message}</p>}
        <form onSubmit={handleResetPassword}>
          <input
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm new password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
